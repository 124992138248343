import React, { useState, useRef, useEffect } from 'react';
import {
	useQuery,
	useLazyQuery,
	gql
} from "@apollo/client";
import ReactToPrint from "react-to-print";
import StartListClass from './StartListClass';
import { Button } from "react-bootstrap";

function Runners({year}) {

	let componentRefs = useRef([]);
	//let allRunners = [];
	const [sorting, setSorting] = useState('bib');
	const [showNonPaid, setShowNonPaid] = useState(true);

	const [allRunners, setAllRunners] = useState([]);
	//if (!runner) {

	console.log('loading runners ');


	const RUNNERS_QUERY = gql`
	  query getKanallopetRunnerListing($filter: String!)		     {
	  getKanallopetRunnerListing(filter: $filter) {
	
	  edges {
	    node {
	      id
	      name
	      club
		  isAdult
		  bus
		  startNo
		  competitionClass {
				... on object_KanallopetClass {
					name
				}
			}
		  parent {
			... on object_KanallopetApplication {
				paidAt
			}
		}
	    }
	  }
	  }
	}
	`;
	
	
	

	const path = '/kanallopet/'+year+'/applications/%';


    const [fetchRunners, {loading, error}] = useLazyQuery(RUNNERS_QUERY, {
		variables: { filter: '{"o_path": {"$like" : "'+path+'"}}' },
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'network-only',
		onCompleted: data => {
			const edges = data.getKanallopetRunnerListing.edges;
			setAllRunners(edges.map(runner => {return {id: runner.node.id, name: runner.node.name, club: runner.node.club, isAdult: runner.node.isAdult, 
													  bus: runner.node.bus, bib: runner.node.startNo, className: runner.node.competitionClass.name, paidAt: runner.node.parent.paidAt };}));
		}
					
	});
	
	
	
/*
	if (loading) {
		console.log('loading runner');
		return (
			<div>
				Hämtar löpare ...
			</div>
		);
	}
	if (error) {
		console.log('error loading runner');
		return `Error! ${error}`;
	}
	
	
	*/
	
	useEffect(() => {
		   fetchRunners();
    }, []);
    
    if (allRunners.length === 0) {
		console.log('loading runner');
		return (
			<div>
				Hämtar löpare ...
			</div>
		);
	}
    
    
	


	const classes = [];
	allRunners.forEach(r => {
		let competitionClass = classes.find(c => c.name === r.className);
		if (!competitionClass) {
			competitionClass = {name: r.className, runners: []};
			classes.push(competitionClass);
		}
		competitionClass.runners.push(r);
	});
	
	classes.forEach(c => {
		c.runners = c.runners.sort((a, b) => a[sorting] === b[sorting] ? 0 : (a[sorting] > b[sorting] ? 1 : -1));
	});
	
	const onChangeSorting = (event) => {
		setSorting(event.target.value);
	}
	
	const onChangeDisplayNonPaid = (event) => {
		setShowNonPaid(event.target.value === 'true');
	}
	
	return (
		<div>
		<button title="Uppdatera" onClick={() => {setAllRunners([]);fetchRunners();}}>🔄</button><br/>
		Sortera efter:
		 <div onChange={onChangeSorting}>
	        <input id="bibSorting" type="radio" value="bib" name="sorting" defaultChecked/> <label htmlFor="bibSorting">Startnummer</label>
	        <input id="nameSorting" type="radio" value="name" name="sorting" /> <label htmlFor="nameSorting">Namn</label>
	      </div>
        Visa ej betalda:
		 <div onChange={onChangeDisplayNonPaid}>
	        <input id="showNonPaid" type="radio" value="true" name="nonpaiddisplay" defaultChecked/> <label htmlFor="showNonPaid">Visa</label>
	        <input id="notShowNonPaid" type="radio" value="false" name="nonpaiddisplay" /> <label htmlFor="notShowNonPaid">Dölj</label>
	      </div>
		{classes.map((competitionClass, i) => (
			<p>
				<ReactToPrint
		          trigger={() => <Button>Skriv ut {competitionClass.name} ({competitionClass.runners.length})</Button>}
		          content={() => componentRefs.current[i]}
		        />
			</p>
		))}
						
		{classes.map((competitionClass, i) => (
			<p>

				<div style={{ display: "block" }}>
		        	<StartListClass competitionClass={competitionClass} showNonPaid={showNonPaid} lineSize={13} headingSize={25} ref={(el) => (componentRefs.current[i] = el)} />
		        </div>
			</p>
		))}			
						
						

		</div>
	);
}

export default Runners;