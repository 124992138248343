import React, { useState, useRef, useEffect }  from 'react';


import { Link } from "react-router-dom";


function ApplyForm({application, onSave, onStartNoChange, from, to}) {
	const emptyApplication = {startno: '', name: '', club: '', dob: '', status: 'Ny', paymenttype: ''};
	application = application ? application : emptyApplication;
	const [state, setState] = useState(application);
	const [errorMsg, setErrorMsg] = useState('');
	const [viewNewStationForm, setViewNewStationForm] = useState(false);
	const nameInputReference = useRef(null);
	
	if (application.startno != state.startno) {
		setState(application);
		nameInputReference.current.focus();
	}

	
	const handleStateChange = (property, value) => {
		const rf = {...state};	
		rf[property] = value;
		setState(rf);
	}
		
	
	const handleStartnoChange = (event) => {	
		onStartNoChange(parseInt(event.target.value));
	}
	const handleNameChange = (event) => {
		handleStateChange('name', event.target.value);
	}
	const handleClubChange = (event) => {	
		handleStateChange('club', event.target.value);
	}
	const handleDobChange = (event) => {	
		handleStateChange('dob', parseInt(event.target.value));
	}

	function handleTypeChange(event) {
		handleStateChange('paymenttype', event.target.value);
	}
	
	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
            onSaveForm(event);
        }
	}

	const onSaveForm = (event) => {		

		let error = '';
		
		if (!state.name) {
			error += " Namn saknas.";
		}
		if (!state.club) {
			error += " Klubb/ort saknas.";
		}
		if (!state.dob) {
			error += " Födelseår saknas.";
		}
		if (!state.paymenttype) {
			error += " Betalningssätt saknas.";
		}
		if (isNaN(state.dob) || parseInt(state.dob) < 1900 || parseInt(state.dob) > new Date().getFullYear()) {
			error += " Ogiltigt födelseår.";
		}
		const startNo = parseInt(state.startno);
		if (startNo < from || startNo > to) {
			error += " Ogiltigt startnummer för klassen.";
		}

		if (!error) {
			onSave( state );
		}
		setErrorMsg(error);
	}




	return (
		<>

		<span className="errorMessage">{errorMsg}</span>
		<table className="applytable">
			<tbody>
			<tr>
				<td className="applytable">Nummer</td>
				<td><input type="number" name="startno" value={state.startno} onChange={handleStartnoChange} step="1"/>
				</td>
				<td>{state.reference ? 'Referensnummer' : ''}</td>
				<td>{state.reference ? state.reference : ''}</td>
			</tr>
			<tr>
				<td>Namn</td>
				<td><input type="text" name="name"  ref={nameInputReference} value={state.name}  onChange={handleNameChange} onKeyUp={(e) => {handleKeyPress(e)}}/></td>
				<td>Klubb/Ort</td><td><input type="text" name="club"  value={state.club}  onChange={handleClubChange} onKeyUp={(e) => {handleKeyPress(e)}}/></td>
				<td>Födelseår</td><td><input type="number" name="dob"  value={state.dob}  onChange={handleDobChange} step="1" size="5" onKeyUp={(e) => {handleKeyPress(e)}}/></td></tr>
				<tr>
					<td>Betalsätt</td>
					<td colSpan={3}>
						<label htmlFor="swish">Swish</label><input type="radio" id="swish" name="paymenttype" value="swish" checked={state.paymenttype === 'swish'} onChange={handleTypeChange} onKeyUp={(e) => {handleKeyPress(e)}}/> &nbsp;&nbsp;
						<label htmlFor="cache">Kontant</label><input type="radio" id="cache" name="paymenttype" value="cache" checked={state.paymenttype === 'cache'} onChange={handleTypeChange} onKeyUp={(e) => {handleKeyPress(e)}}/> &nbsp;&nbsp;
						<label htmlFor="switch">Klassbyte</label><input type="radio" id="switch" name="paymenttype" value="classchange" checked={state.paymenttype === 'classchange'} onChange={handleTypeChange} onKeyUp={(e) => {handleKeyPress(e)}}/> &nbsp;&nbsp;
						<label htmlFor="free">Fri start</label><input type="radio" id="free" name="paymenttype" value="free" checked={state.paymenttype === 'free'} onChange={handleTypeChange} onKeyUp={(e) => {handleKeyPress(e)}}/>
					</td>
				</tr>
				<tr>
					<td colSpan="5">{state.status}</td>
					<td align="right">
						<button type="button" name="saveApplication" onClick={onSaveForm}>Spara</button></td></tr>
			</tbody>
		</table>
		</>
	);
}

export default ApplyForm;
