import React from 'react';

	
//function ResultPrintingClass({resultClass}) {
class StartListClass extends React.PureComponent { 
	constructor(props) {
	    super(props);
	}
	


	render() {
		const competitionClass = this.props.competitionClass;

		let c = 0;
		const hSize = this.props.headingSize || 25;
		const lSize = this.props.lineSize || 12;

		return (
			<div className="printstart">
			<h1 style={{fontSize: `${hSize}px`}}>{competitionClass.name}</h1>
			<table className="startdisplaytable" >
				<tbody>
					{competitionClass.runners.map(runner => ( !this.props.showNonPaid && (runner.paidAt === null || runner.paidAt.length === 0) ? '' :  <tr className={"startdisplayrow" + (runner.paidAt != null && runner.paidAt.length > 0 ? '' : ' notpaid')} key={runner.id+c++} style={{fontSize: `${lSize}px`}}>  
													<td align="right">{runner.bib}</td>
													<td>{runner.name}</td>
													<td>{runner.club}</td>
													<td>{this.props.showNonPaid ? (runner.paidAt != null && runner.paidAt.length > 0 ? 'ja' : 'nej') : '' }</td>
												</tr>))}
				</tbody>
				
			</table>
			</div>
		);
}
}

export default StartListClass;
