import React from 'react';
import {
	useQuery,
	gql,
} from "@apollo/client";
import moment from 'moment';
import { printFormat, compare } from './ResultUtil';

let modefiedSince = 0;
let allResults = [];
const classes = ['10 km', '21 km', '21 km K', '21 km M', '5 km'];


function ResultDisplay({year}) {


	const RESULTS_QUERY = gql`
	  query getKanallopetResultListing($filter: String!)		     {
	  	getKanallopetResultListing(filter: $filter, sortOrder: "ASC") {
	
		  edges {
		    node {
				runner {
		            ... on object_KanallopetRunner {
		               	id
		                name
		                club
		              	startNo
						competitionClass {
							... on object_KanallopetClass {
								name
								startTime
							}
						}
		            }
		       }
				id
			    result
			    status
			    pagination
				modificationDate
		  }
	  	}
      }
	}
	`;
	

	
	
	const path = '/kanallopet/'+year+'/results/%';
	
	
	const { loading, error, data, refetch } = useQuery(RESULTS_QUERY, {
		variables: {filter: '{"o_path": {"$like" : "'+path+'"}, "o_modificationDate": {"$gt" :"'+modefiedSince+'"}, "status": {"$like" : "%ok%"}}'  },
		pollInterval: 10000,
	});
	

	

	if (loading ) {
		console.log('loading results');
		return (
			<div>
				Laddar resultat ...
			</div>
		);
	}
	if (error) {
		console.log('error loading results');
		return `Error! ${error}`;
	}

	
	
	
	if (data) {
		const edges = data.getKanallopetResultListing.edges;
		const fetchedResults = edges.map(result => {return {id: result.node.id, result: result.node.result, status: result.node.status, 
			pagination: result.node.pagination, runner: result.node.runner, modificationDate: result.node.modificationDate };});
		
		const newResults = [];
		allResults.forEach(result => {
			if (!fetchedResults.find(r => {return r.pagination === result.pagination})) {
				newResults.push(result);
			}
		});
		newResults.push(...fetchedResults);
		allResults = newResults.filter(r => r.runner).sort((a,b) => b.pagination-a.pagination);
		
	}
	allResults.forEach(result => {
		modefiedSince = result.modificationDate > modefiedSince ? result.modificationDate : modefiedSince;
	});
	
	const allRankings = {'10 km': {rankings: []}, '21 km': {rankings: []}, '21 km K': {rankings: []}, '21 km M': {rankings: []}, '5 km': {rankings: []}};
	allResults.forEach(result => {
		allRankings[result.runner.competitionClass.name].rankings.push(result);
	});
	
	for (const key in allRankings) {
		const classRanking = allRankings[key];
		classRanking.rankings = classRanking.rankings.sort((a, b) => compare(a.result, b.result));
		classRanking.rankings.forEach((r, i) => {
			r.place = i+1
			});
	};
	
	console.log('dispaly results:'+modefiedSince);
	return (
		<>
		<table className="resultdisplaytable">
			<thead>
				<tr><th>No</th><th>Namn</th><th>Klubb</th><th>Klass</th><th>Tid</th><th>Plac</th></tr>
			</thead>
			<tbody>
				{allResults.map(result => ( <tr className="resultdisplayrow" key={result.id}>  
												<td align="right">{result.runner.startNo}</td>
												<td>{result.runner.name}</td>
												<td>{result.runner.club}</td>
												<td>{result.runner.competitionClass.name}</td>
												<td align="right">{printFormat(result.result)}</td>
												<td align="right">{result.place}</td>
											</tr>))}
			</tbody>
			
		</table>
		</>
	);
}

export default ResultDisplay;