import React, { useState, useEffect } from 'react';
import {gql, useQuery, useMutation, useLazyQuery} from '@apollo/client';
import ErrorMessage from '../ErrorMessage';
import ReadingList from './ReadingList';
import Scanner from './Scanner';
import {getReadingUnit, TIME_READING, BIB_READING} from '../ReadingUnit/ReadingUnit';
import UnitSelect from './UnitSelect';
import Clock, {getFormatedTime, reloadClock} from '../Clock';

// Define mutation
const REGISTER_READING_MUTATION = gql`
 mutation RegisterReading($key: String!, $path: String!, $readingUnit: Int!, $pagination: Int!, $readTime: String!, $readingType: String!, $primary: Boolean!, $readData: String!) {
  createKanallopetReading(key: $key, published: true, path: $path, input: {readingUnit: $readingUnit, pagination: $pagination, readTime: $readTime, readingType: $readingType, primary: $primary, readData: $readData}) {
    success
    message
    output {
      id
	  key
      pagination
    }
  }
}
`;
const REGISTER_READING_MUTATION_2 = gql`
 mutation addKanallopetReadingMutation($key: String!, $path: String!, $readingUnit: Int!, $pagination: Int!, $readTime: String!, $readingType: String!, $primary: Boolean!, $readData: String!) {
  addKanallopetReadingMutation(key: $key, published: true, path: $path, input: {readingUnit: $readingUnit, pagination: $pagination, readTime: $readTime, readingType: $readingType, primary: $primary, readData: $readData}) {
    success
    message
    output {
      id
	  key
      pagination
      result
      resultStatus
      runnerName
      runnerClub
    }
  }
}
`;
const READING_LIST_QUERY = gql`
		     query getKanallopetReadingListing($filter: String!)  {
			  getKanallopetReadingListing(filter: $filter, sortBy: "pagination", sortOrder: "DESC") {
			    edges {
			      node {
			        id
					key
			        readingUnit
			        pagination
			        readData
					readTime
					primary
					result {
					  result
					  status
					  runner {
						... on object_KanallopetRunner {
						  name
						  club
						}
					  }
					}
			      }
			    }
			  }
			}
`;

const READING_QUERY = gql`
		     query getKanallopetReading($id: Int!)  {
			  getKanallopetReading(id: $id) {
				id
				key
				readingUnit
				pagination
				readData
				readTime
				primary
				result {
				  result
				  runner {
					... on object_KanallopetRunner {
					  name
					  club
					}
				  }
				}
			  }
			}
`;



const UNIT_STORAGE_KEY = 'reading-unit';

let checkRegDone = false;
let unitLists = {};
let list = [];

const PAGINATION_KEY_PREFIX = 'unit-pagination-';
const ON_REGISTRATION_STORAGE_KEY_PREFIX = 'finishtime-onreg-';

function Finish({year}) {
	const [muteTime, setMuteTime] = useState(0);
	const storedUnit = getReadingUnit();
	const [unit, setUnit] = useState(storedUnit);
	const [lastReading, setLastReading] = useState(null);
	
	const paginationKey = PAGINATION_KEY_PREFIX + (unit ? unit.id : 0);
	let path = '/kanallopet/'+year+'/readings/'+ (unit ? unit.key + '/' : '');
	const [readings, setReadings] = useState([]);
	const storedPagination = localStorage.getItem(paginationKey);
	console.log('pagination is '+storedPagination+' number of readings are: '+readings.length);
	
	const [pagination, setPagination] = useState(storedPagination ? parseInt(storedPagination) : 1);
	const [registeredPagination, setRegisteredPagination] = useState(0);
	console.log('state pagination is '+pagination);
	
	const initReadings = unit => {
		const pagination = parseInt(localStorage.getItem(PAGINATION_KEY_PREFIX + (unit ? unit.id : 0)) || 1);
		const readings = unit && unitLists.hasOwnProperty(unit.id) ? unitLists[unit.id] : [];
		if (unit) {
			for (let p = 1; p <= pagination; p++) {
				const key = unit.readingType + '_' + unit.id + '_' + pagination;
				const storeKey = ON_REGISTRATION_STORAGE_KEY_PREFIX + unit.readingType + '_' + unit.id + '_' + p;
				const storedReading = JSON.parse(localStorage.getItem(storeKey));

				if (storedReading) {
					const reading = readings.find(el => el.pagination === p);
					if (!reading) {
						readings.push(storedReading);
					}
				}
			}
		}
		path = '/kanallopet/'+year+'/readings/'+ (unit ? unit.key + '/' : '');
		setReadings(readings);
		setPagination(pagination);		
		
	}
	
	const onUnitChange = unit => {
		//if (unit) {
			localStorage.setItem(UNIT_STORAGE_KEY, JSON.stringify(unit));
			setUnit(unit);
			initReadings(unit);
						
		//}

	}

	const parseReading = node => {
		return  {
			id: parseInt(node.id),
			key: node.key,
			pagination: node.pagination,
			readTime: node.readTime,
			readData: node.readData,
			primary: node.primary,
			readingUnit: parseInt(node.readingUnit),
			result: node.result.length > 0 ? node.result[0]: null};
	}

	const [getReading, {refetch: refetchGetReading }] = useLazyQuery(READING_QUERY, {
		variables: { },
		onCompleted: data => {
			console.log('getReading returned ');
			const theReading = parseReading(data.getKanallopetReading);
			const reading = list.find(el => el.id === theReading.id);
			if (reading) {
				reading.result = theReading.result;
				setReadings(list);
				setMuteTime(new Date().getTime());
				if (theReading.pagination > registeredPagination) {
					setRegisteredPagination(theReading.pagination);
				}
			}

		}
	});
	
	const [callMutation, { error: rError }] = useMutation(REGISTER_READING_MUTATION_2, {
		variables: { path: path, readingUnit: unit ? unit.id : 0, readingType: unit ? unit.readingType : '' }, 
		onCompleted: data => {
			if (!data.addKanallopetReadingMutation.success) {
				console.log('addKanallopetReadingMutation failed:' + data.addKanallopetReadingMutation.message);
				return;
			}
			const storageKey = ON_REGISTRATION_STORAGE_KEY_PREFIX+unit.readingType+'_'+unit.id+'_'+data.addKanallopetReadingMutation.output.key;
			localStorage.removeItem(storageKey);
			
			const thePagination = data.addKanallopetReadingMutation.output.pagination;
			console.log('I mål reg completed '+thePagination+' '+data.addKanallopetReadingMutation.output.id);
			list = readings.length ? readings : list;
			const reading = list.find(el => el.pagination === thePagination);
			if (reading) {
				console.log('found reading to update id');
				const output = data.addKanallopetReadingMutation.output;
				reading.id = parseInt(output.id);
				reading.result = {
					result: output.result,
					status: output.status,
					runner: {
						name: output.runnerName,
						club: output.runnerClub
					}

				};
				setReadings(list);
				//console.log("refetchGetReading:"+reading.id);
				//refetchGetReading({id: reading.id});
				//getReading();
				//refetchGetReading({id: 15018});
				setLastReading(reading);
				setMuteTime(new Date().getTime());
			}
			if (thePagination > registeredPagination) {
				setRegisteredPagination(thePagination);
			}

		}
	});
	
	const checkRegistrations = (list, alwaysSet) => {
		console.log('checkRegistrations');		
		const readingsToRegister = [];
		let hasChanged = false;
		for (let p = 1; p < pagination; p++) {
			const storeKey = ON_REGISTRATION_STORAGE_KEY_PREFIX+unit.readingType+'_'+unit.id+'_'+p;
			const storedReading = JSON.parse(localStorage.getItem(storeKey));
			if (storedReading) {
				const reading = list.find(el => el.pagination === p);
				if (!reading || !reading.id) {
					readingsToRegister.push(storedReading);
				}
				else {
					localStorage.removeItem(storeKey);					
				}
				if (!reading) {
					list.push(storedReading);	
					hasChanged = true;
				}
			}
		}
		if (hasChanged || alwaysSet) {
			list = list.sort((a, b) => b.pagination - a.pagination );	
			setReadings(list);
		}
		unitLists[unit.id] = list;
		if (!checkRegDone) {
			checkRegDone = true;
			readingsToRegister.forEach(r =>  {
				callMutation({ variables: r });
			})
		}
		
	}
	
	
	
	list = readings ? readings : [];
	const variables  = '{"readingUnit": {"$like" : '+(unit ? unit.id : 0) +'}, "o_path": {"$like" : "'+path+'"}}';
	const { loading: lLoading, error: lError, data: lData } = useQuery(READING_LIST_QUERY, {
		variables: { filter: variables },
		fetchPolicy: 'network-only',
		onCompleted: data => {

			const response = data.getKanallopetReadingListing.edges.map(el => (parseReading(el.node)));
			if (response.length && unit && unit.id == response[0].readingUnit) {
				list = response;
				console.log('set readings '+unit.id);

				checkRegDone = false;
				checkRegistrations(list, list.length);
				if (list.length) {
					if (list[0].pagination >= pagination) {
						localStorage.setItem(paginationKey, list[0].pagination + 1);
						setPagination(list[0].pagination + 1);
					}
					setLastReading(list[0]);
				}
			}			
		},
		onError: data => {
			initReadings(unit);
		}
	});


		
	
	const registerReading = (data) => {
		if (unit.readingType === BIB_READING) {
			if (isNaN(parseInt(data))) {
				return;
			}
		}
		const storedPagination = localStorage.getItem(paginationKey);
		const pagination = storedPagination ? parseInt(storedPagination) : 1; // used stored pagination instead of state to get hand scanner working
		const key = unit.readingType+'_'+unit.id+'_'+pagination;
		const storageKey = ON_REGISTRATION_STORAGE_KEY_PREFIX+key;
		const timestamp = getFormatedTime();
		const readData = data+'';

		console.log('I mål '+pagination);
		localStorage.setItem(paginationKey, pagination +1);
			
		const reading = { id: 0, key: pagination+'',  pagination: pagination, readTime: timestamp, readData: readData, primary: unit.primary ? true : false, readingUnit: parseInt(unit.id), readingType: unit.readingType, result: null };
		localStorage.setItem(storageKey, JSON.stringify(reading));
	
		setPagination(pagination + 1);
		readings.push(reading);	
		setReadings(readings);
		callMutation({ variables: reading });					
		console.log('I mål pushed'+pagination);
	} 
		
	const onFinish = (event) => {
		if (unit.readingType === BIB_READING) {
			const el = document.getElementById('bib');
			registerReading(el.value);
			el.value = '';
		}
		else {
			registerReading(getFormatedTime(true));
		}
	}
	
	const onCheck = (event) => {
		checkRegDone = false;
		checkRegistrations(readings, false);
	}
	
	
   if (!unit) {
		return (<><h2>Måltid</h2><UnitSelect year={year} unit={unit} setUnit={onUnitChange} /></>);
	}

console.log('render '+list.length);
	return (
		 <div>
			<h2>{unit.readingType === BIB_READING ? 'Nummer' : 'Måltid'}</h2><Clock />
			<ErrorMessage message={rError ? rError.message : ''} />
			<ErrorMessage message={lError ? lError.message : ''} />
			<UnitSelect year={year} unit={unit} setUnit={onUnitChange} />
            
			   <span>Nästa: {pagination}</span><br/>
				{unit.readingType === BIB_READING ? <Scanner registerReading={registerReading} lastReading={lastReading}/> : ''}
				{unit.readingType === TIME_READING ? <button type="button" className="registertime" onClick={onFinish}>Registrera</button> : ''}
				<p/>
				

			<button type="button" onClick={onCheck}>Skicka om oregistrerade</button>
			<br/>
			<ReadingList listLoading={lLoading} readings={readings} unit={unit}/>
        </div>
	);
}

export default Finish;
