//import React, { useState, useEffect } from 'react';
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";

function Invite ({setIsSignedIn, setYear, setServer}) {
	
	let [searchParams, setSearchParams] = useSearchParams();
	
	const apiKey = searchParams.get('apiKey');
	if (apiKey) {
		setIsSignedIn(apiKey);
	}
	
	const year = searchParams.get('year');
	if (year) {
		setYear(year);
	}
	
	const server = searchParams.get('server');
	if (server) {
		setServer(server);
	}
	
    return (
        <div className="Invite">
			<h1>Välkommen!</h1>
			<Link to="/">Dokumentation</Link>
        </div>
    );
}

export default Invite;
