

function printFormat(result) {
	const parts = result.split(':');
	if (parts.length < 3) {
		return result;
	}
	let hour = parts[0];
	if (hour === '0' || hour === '00') {
		return parts[1] + ':' + parts[2];
	}
	if (hour.length == 2 && hour.charAt(0) === '0') {
		hour = hour.charAt(1);
	}
	return hour + ':' + parts[1] + ':' + parts[2];
}

function compare(a, b) {
	const aTimes = a.split(':').reverse();
	const bTimes = b.split(':').reverse();
	const factors = [1, 60, 3600];
	let timeA = 0;
	let timeB = 0;
	for (let i=0; i<aTimes.length; i++) {
		timeA += aTimes[i] * factors[i];
	}
	for (let i=0; i<bTimes.length; i++) {
		timeB += bTimes[i] * factors[i];
	}
	return timeA - timeB;
}

export {printFormat, compare};