import React, { useState } from 'react';

import BarcodeScannerComponent from "react-qr-barcode-scanner-17";
import {BIB_READING} from "../ReadingUnit/ReadingUnit";
import {getFormatedTime} from "../Clock";

const RUNNER_PLACARD_SIZE_KEY = 'runner_placard_size_key';

let lastScan = '';

let serialPort = null;
let reader = null;

function Scanner({registerReading, lastReading}) {
	
  const beep = new Audio('/kanallopet/beep.mp3');
	
  const [show, setShow] = useState('manuell');
  const [scan, setScan] = useState('');
  const [serialState, setSerialState] = useState('unsuported');
  const [showRunner, setShowRunner] = useState(false);
  const [placardSize, setPlacardSize] = useState(localStorage.getItem(RUNNER_PLACARD_SIZE_KEY) || 50);


	const onBib = (event) => {
		lastScan=event.target.value;
		event.preventDefault();
		if (event.keyCode === 13) {
			registerReading(event.target.value);
			event.target.value = '';
		}
	}

	const onScan = (bib) => {
		registerReading(bib);
	}

	const onRegister = (event) => {
		const el = document.getElementById('bib');
		lastScan=el.value;
		registerReading(el.value);
		el.value = '';
	}


	const handScanUpdate = (data) => {
		if (data) {
			console.log("new hand scan:"+data);
			const number = validate(data);
			if (number && number != lastScan) {
				registerReading(number);
				lastScan = number;
				setShowRunner(true);
			}
		}
	}

  const scanUpdate = (err, result) => {
	if (result) {
		console.log("new scan:"+result.getText());
		const res = validate(result.getText());
		console.log("scan value:"+res);
		if (res !== false && res != lastScan) {
			if (res != scan) {
				setScan(res);
				if (res != lastScan) {
					beep.play();
				}
				lastScan = res;
			}
		}
	}
  }


  const confirmScan = () => {
	if (!scan) {
		return null;
	}
	
	return (
		<>
			<button type="button" className="okscan" onClick={() => {onScan(scan);setScan('')}}>Bekräfta<br/>{scan}</button>&nbsp;
			<button type="button" className="cancelscan" onClick={() => {lastScan='';setScan('')}}>Scanna om</button>
		</>
	);
  }

 const validate = (scan) => {
	 console.log(scan);
	 if (scan.substring(0, 1) !== '0') {
		 // does not start with 0, no check digit
		 return scan;
	 }

	if (scan.length < 2) {
		return false;
	}	
    const value = parseInt(scan.substring(0, scan.length - 1));
    let number = value;
    const check = parseInt(scan.substring(scan.length - 1, scan.length));
    let sum = 0;
    for (let i = 0; number > 0; i++) {
      let t = number % 10;
      sum += t * (i % 2 == 0 ? 3 : 1);
      number = Math.floor(number / 10);
    }
    let res = (10 - (sum % 10)) % 10;
	return res === check ? value : false;
  }
	const closeSerialPorts = (scan) => {
		if (serialPort) {
			reader.releaseLock();
			serialPort.close();
		}
	}

	const readSerialPorts = async (serialPort) => {
		reader = serialPort.readable.getReader();
		console.log(serialPort);
		if (serialPort && serialPort.readable) {
			try {
				let serialPartValue = '';
				while (true) {
					console.log('waitinig');
					const { value, done } = await reader.read();
					if (done) {
						// Allow the serial port to be closed later.
						console.log("release lock");
						reader.releaseLock();
						break;
					}
					if (value) {
						let string = new TextDecoder().decode(value);
						console.log("getting value:"+string);
						if (serialPartValue === '') {
							setTimeout(function(){
								console.log('final value:' + serialPartValue);
								handScanUpdate(serialPartValue.trim());
								serialPartValue = '';
							}, 100);
						}
						serialPartValue += string;
					}
				}

			}
			catch (error) {
				console.log(error);
			}
			console.log("done waiting");
		}

	}


	const initSerialPorts = (scan) => {
		if (typeof navigator.serial === 'undefined') {
			alert('Stöd för läsning av seriell port saknas.');
			return false;
		}

		navigator.serial
			.requestPort()
			.then((port) => {
				console.log("continue B");
				console.log(port);
				serialPort = port;
				/*
				serialPort.setSerialPortParams(9600,
		SerialPort.DATABITS_8,
		SerialPort.STOPBITS_2,
		SerialPort.PARITY_NONE);
		*/
				port.open({ baudRate: 9600, dataBits: 8,  stopBits: 2, parity: 'none'}).then(() => {
					console.log('port opened:'+ serialPort);
					console.log(serialPort);
					/*
					const textDecoder = new TextDecoderStream('hej');
					const readableStreamClosed = serialPort.readable.pipeTo(textDecoder.writable);
					reader = textDecoder.readable.getReader();
*/
					//reader = serialPort.readable.getReader();
					readSerialPorts(serialPort);
					/*
					while (serialPort.readable) {
						  const reader = serialPort.readable.getReader();
						console.log("readable");
						  try {
							  let count = 0;
							  let isDone = false;
							while (!isDone && count < 100) {
								const res = reader.read().then((result) => {
									//console.log("value:"+value + " done:"+done);
									console.log("value:"+result);

									if (done) {
										// Allow the serial port to be closed later.
										console.log("release lock");
										reader.releaseLock();
										isDone = true;
										return;
									  }
									  if (value) {
										  console.log("getting a value");
										console.log(value);
									  }

								},
								(reason) => {
									console.log(reason)
								});
								console.log(res);
								count++;

							}
						  } catch (error) {
							// TODO: Handle non-fatal read error.
						  }
						  reader.releaseLock();
						  serialPort.close();
						}
						*/
				});
			})
			.catch((e) => {
				console.log("error B");
			});


		return true;
	}



	if ("serial" in navigator) {
		console.log("Awesome, The serial port is supported.");
		if (serialState === 'unsuported') {
			setSerialState('off');
		}
		navigator.serial.addEventListener("connect", (e) => {
			// Connect to `e.target` or add it to a list of available ports.
			console.log("event connect")
		});

		navigator.serial.addEventListener("disconnect", (e) => {
			// Remove `e.target` from the list of available ports.
			console.log("Remove `e.target` from the list of available ports.")
		});
	}
	else {
		console.log("No,  serial port is not supported.")
	}

	const showInput = () => {
		if (show != 'handscan' && serialState === 'on') {
			closeSerialPorts();
			setSerialState('off');
		}
		switch (show) {
			case 'manuell':
				return (<div><label>Nummerlapp:</label> <input id="bib" type="number" size="5" onKeyUp={onBib}/>
					<button type="button" onClick={onRegister}>Registrera</button>
				</div>)
			case 'mobil':
				return (
					<div style={{maxWidth: 400}}>
						{confirmScan()}
						<div hidden={scan ? "hidden" : ""}><BarcodeScannerComponent width="100%" onUpdate={scanUpdate}/>
						</div>
					</div>)
			case 'handscan':
				if (serialState === 'off') {
					if (initSerialPorts()) {
						setSerialState('on');
						return (<div>Handscanner</div>)
					}
				}
				else if (serialState === 'unsuported') {
					return (<div>Support för handscanner saknas</div>)
				}

			default:
				return null;
		}
	}

	const adjustPlacardSize = (change) => {
		const newSize = parseInt(placardSize) + change;
		localStorage.setItem(RUNNER_PLACARD_SIZE_KEY, newSize);
		setPlacardSize(newSize);
	}


	const runnerDisplay = () => {
		if (!showRunner) {
			return null;
		}
		return (
			<div className="modal">
				<div className="modal_content">
					{lastReading && lastReading.pagination}
					<button onClick={() => {adjustPlacardSize(1)}}>⬆️</button>
					<button onClick={() => {adjustPlacardSize(-1)}}>⬇️</button>
					<div style={{fontSize: `${placardSize}px`}}>
						<span className="close" onClick={() => {
							setShowRunner(false)
						}}> &times;</span><br/>
						{lastReading && lastReading.readData}<br/>
						{lastReading && lastReading.result && lastReading.result.runner && lastReading.result.runner.name},&nbsp;
						{lastReading && lastReading.result && lastReading.result.runner && lastReading.result.runner.club}<br/>
						{lastReading && lastReading.value} {lastReading && lastReading.result && lastReading.result.result}
					</div>
				</div>
			</div>);
	}

	return (
		<div>
			Inmatningstyp:<select value={show} onChange={e => setShow(e.target.value)}>
				<option value="manuell">Manuell</option>
				<option value="mobil">Mobil</option>
				<option value="handscan">Handscanner</option>
			</select>
<br/>
			<button onClick={() => setShowRunner((st) => !st)}>Löpare i stortext</button>
			<p>
			{
				showInput()
			}
			{runnerDisplay()}
			</p>
		</div>
	);
}

export default Scanner;
