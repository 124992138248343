import React, { useState, useRef } from 'react';
import {
	useQuery,
	gql,
} from "@apollo/client";
import moment from 'moment';
import { Link } from "react-router-dom";
import ResultPrintingClass from './ResultPrintingClass';
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { compare } from './ResultUtil';

const PRINT_RESULT_HEADING_SIZE_KEY = 'print_result_heading_size_key';
const PRINT_RESULT_LINE_SIZE_KEY = 'print_result_line_size_key';

let modefiedSince = 0;
let allResults = [];
let json = null;

	



function ResultPriniting({year}) {

	
	let componentRefs = useRef([]);
	
	const RESULTS_QUERY = gql`
	  query getKanallopetResultListing($filter: String!)		     {
	  	getKanallopetResultListing(filter: $filter, sortOrder: "ASC") {
	
		  edges {
		    node {
				runner {
		            ... on object_KanallopetRunner {
		               	key
		                name
		                club
		              	startNo
						competitionClass {
							... on object_KanallopetClass {
								name
							}
						}
		            }
		       }
				id
			    result
			    status
			    pagination
				modificationDate
		  }
	  	}
      }
	}
	`;
	

	const [startNos, setStartNos] = useState({'10 km': 1, '21 km': 1, '21 km K': 1, '21 km M': 1, '5 km': 1});
	const [sent, setSent] = useState('');
	const [linefont, setLinefont] = useState(localStorage.getItem(PRINT_RESULT_LINE_SIZE_KEY) || 20);
	const [headfont, setHeadfont] = useState(localStorage.getItem(PRINT_RESULT_HEADING_SIZE_KEY) || 30);
	

	const path = '/kanallopet/'+year+'/results/%';
	
	
	
	const { loading, error, data, refetch } = useQuery(RESULTS_QUERY, {
		variables: {filter: '{"o_path": {"$like" : "'+path+'"}, "o_modificationDate": {"$gt" :"'+modefiedSince+'"}, "status": {"$like" : "%ok%"}}'  },
		pollInterval: 10000,
		onCompleted: data => {
			const edges = data.getKanallopetResultListing.edges;
			const fetchedResults = edges.map(result => {return {id: result.node.id, result: result.node.result, status: result.node.status, 
				pagination: result.node.pagination, runner: result.node.runner, modificationDate: result.node.modificationDate };});
			
			const newResults = [];
			allResults.forEach(result => {
				if (!fetchedResults.find(r => {return r.pagination === result.pagination})) {
					newResults.push(result);
				}
			});
			newResults.push(...fetchedResults);
			allResults = newResults.filter(r => r.runner).sort((a,b) => b.pagination-a.pagination);
			
			allResults.forEach(result => {
				modefiedSince = result.modificationDate > modefiedSince ? result.modificationDate : modefiedSince;
			});
			
			const allRankings = {'10 km': {rankings: []}, '21 km': {rankings: []}, '21 km K': {rankings: []}, '21 km M': {rankings: []}, '5 km': {rankings: []}};
			allResults.forEach(result => {
				allRankings[result.runner.competitionClass.name].rankings.push(result);
			});
		
			const now = moment();			
			const exportData = {classes: [], updated: now.add(modefiedSince*1000 - now).format('YYYY-MM-DD HH:mm:ss')};
			for (const key in allRankings) {
				const classData = {name: key, results: []};
				const classRanking = allRankings[key];
				classRanking.rankings = classRanking.rankings.sort((a, b) => compare(a.result, b.result));
				classRanking.rankings.forEach((r, i) => {
					r.place = i+1
					classData.results.push({id: r.runner.key, place: i+1, name: r.runner.name, club: r.runner.club, result: r.result, no: r.runner.startNo});
				});
				exportData.classes.push(classData);
			};

			if (json === null || json.updated !== exportData.updated) {
				json = exportData;
				setSent(moment().format('YYYY-MM-DD HH:mm:ss'));
			}	
			
		},
		onError: data => {
			console.log('Exporting results failed');			
		}
	});
	
	const onLineSize = (event) => {
		const el = document.getElementById('linesize');
		setLinefont(el.value);
		localStorage.setItem(PRINT_RESULT_LINE_SIZE_KEY, el.value);
	}
	
	const onHeadSize = (event) => {
		const el = document.getElementById('headsize');
		setHeadfont(el.value);
		localStorage.setItem(PRINT_RESULT_HEADING_SIZE_KEY, el.value);
	}
	
	

	

	if (loading ) {
		console.log('loading results');
		return (
			<div>
				Laddar resultat ...
			</div>
		);
	}
	if (error) {
		console.log('error loading results');
		return `Error! ${error}`;
	}
	if (!json) {
		return null;
	}

	//const resultClass = json.classes[1];
	

	
	return (
		<>
		       <input id="headsize" type="number" size="5" defaultValue={headfont}/> <button type="button" onClick={onHeadSize}>Sätt fontstorlek rubrik</button> ({headfont})<br/>
               <input id="linesize" type="number" size="5"  defaultValue={linefont}/> <button type="button" onClick={onLineSize}>Sätt fontstorlek linje</button> ({linefont})
		       <>
						{json.classes.map((resultClass, i) => (
							<p>
								<ReactToPrint
						          trigger={() => <Button>Skriv ut {resultClass.name} ({resultClass.results.length})</Button>}
						          content={() => componentRefs.current[i]}
						        />
							</p>
						))}
						
						{json.classes.map((resultClass, i) => (
							<p>
								<div style={{ display: "block" }}>
						        	<ResultPrintingClass resultClass={resultClass} lineSize={linefont} headingSize={headfont} ref={(el) => (componentRefs.current[i] = el)} />
						        </div>
							</p>
						))}
				        
					</>
				   
		</>
	);

}

export default ResultPriniting;