import React from 'react';
import '../App.css';
import {BIB, PRIMARY, SECONDARY, TIME, RUNNER} from './Results';

function Result({ result, selectedCell, setSelectedCell, action, primary, secondary, bib }) {
	// <tr><th>Paginering</th><th>Inläsnings-Id</th><th>Primär tid</th><th>Secundär tid</th><th>Startnummer</th><th>Status</th><th>Löpare</th></tr>
	
	const rowSelected = selectedCell.result && selectedCell.result.pagination === result.pagination;
	
	const timeSelected = rowSelected && selectedCell.type === TIME ? ' selected' : '';
	const runnerSelected = rowSelected && selectedCell.type === RUNNER ? ' selected' : '';
	const primarySelected = rowSelected && selectedCell.type === PRIMARY ? ' selected' : '';
	const secondarySelected = rowSelected && selectedCell.type === SECONDARY ? ' selected' : '';
	const bibSelected = rowSelected && selectedCell.type === BIB ? ' selected' : '';
	
	const primaryTimeUsed = !result.timeFrom ||  result.timeFrom.id == primary.id ? ' timefrom' : '';
	const secondaryTimeUsed = result.timeFrom  && result.timeFrom.id == secondary.id ? ' timefrom' : '';
	const bibTimeUsed = result.timeFrom && result.timeFrom.id == bib.id ? ' timefrom' : '';
	const runnerBib = result.runner ? result.runner.startNo : '';
	const readingBib = result.readings && result.readings.bib ? result.readings.bib.readData: '';
	const bibUsed = runnerBib && readingBib && (runnerBib == readingBib) ? ' bibfrom' : '';
	
	
	const selectCell = (type) => {
		if (rowSelected && selectedCell.type === type) {
			setSelectedCell({page: null, id: null, type: null});
		}
		else {
			setSelectedCell({result: result, type: type});
		}
	}
	
	const actions = () => {
		if (!rowSelected) {
			return (<td>{action('setOk', '✅', 'Godkänn', '', {result: result, type: ''})}</td>);
		}
		return (
			<td>
				{action('remove', '❌', 'Ta bort')}
				{action('add', '➕', 'Skjut in')}
				{action('useTime', '⬅', 'Använd tid')}
				{action('edit', '🖊', 'Ändra.')}
				{action('setOk', '✅', 'Godkänn')}
			</td>
		);
	}
	
	const status = () => {
		return (
			<td>
				{result.status.includes('ok') ? (<span title="Godkänd">✅</span>) : ''}
				{result.status.includes('timewarning') ? (<span title="Varning för tidsdiff">🚩</span>) : ''}
				{result.status.includes('missing_runner') && ! runnerBib ? (<span title="Ingen löpare med numret">🚷</span>) : ''}				
			</td>
		);
	}
	
	const syncLine = () => {
		if (result.status.includes('synced')) {
			return (<tr><td className="syncline" colSpan="15">Synkning</td></tr>);
		}
		return null;
	}

	
	let resPage = String(result.pagination && !(result.pagination+'').startsWith('none') ? result.pagination : '');
	if (resPage.endsWith('00')) {
		resPage = resPage.slice(0, -2);
	}
	else if(result.pagination < 100) {
		resPage = 'M' + result.pagination;
	}
	
	
	return (
		<>
		<tr className={result.status.includes('cancelled') ? 'cancelled' : ''}>
			<td className={"pagination"}>{resPage}</td>
			<td className={"object-id"}>{result.id && !result.id.startsWith('none') ? result.id : ''}</td>
			<td className={"time"+timeSelected} onClick={() => selectCell(TIME)}>{result.result ? result.result : ''}</td>
			
			<td className={"bib"+runnerSelected} onClick={() => selectCell(RUNNER)}>{result.runner ? result.runner.startNo : (action('edit', '🖊', 'Ändra.', '', {result: result, type: RUNNER}))}</td>
			<td className={"runner"+runnerSelected} onClick={() => selectCell(RUNNER)}>{result.runner ? result.runner.name : ''}</td>
			<td className={"runner"+runnerSelected} onClick={() => selectCell(RUNNER)}>{result.runner ? result.runner.competitionClass.name : ''}</td>
			
			<td className={"pagination"+primarySelected} onClick={() => selectCell(PRIMARY)}>{result.readings && result.readings.primary ? result.readings.primary.pagination : (result.rpObject && result.rpObject.primary.pagination != 0 ? result.rpObject.primary.pagination : '')}</td>
			<td className={primarySelected+primaryTimeUsed} onClick={() => selectCell(PRIMARY)}>{result.readings && result.readings.primary ? result.readings.primary.readData.substring(11) : ''}</td>
			<td className={"pagination"+secondarySelected} onClick={() => selectCell(SECONDARY)}>{result.readings && result.readings.secondary ? result.readings.secondary.pagination : (result.rpObject && result.rpObject.secondary.pagination != 0 ? result.rpObject.secondary.pagination : '')}</td>
			<td className={secondarySelected+secondaryTimeUsed} onClick={() => selectCell(SECONDARY)}>{result.readings && result.readings.secondary ? result.readings.secondary.readData.substring(11) : ''}</td>
			<td className={"pagination"+bibSelected} onClick={() => selectCell(BIB)}>{result.readings && result.readings.bib ? result.readings.bib.pagination : (result.rpObject && result.rpObject.bib.pagination != 0 ? result.rpObject.bib.pagination : '')}</td>
			<td className={"bib"+bibSelected+bibUsed} onClick={() => selectCell(BIB)}>{readingBib}</td>
			<td className={bibSelected+bibTimeUsed} onClick={() => selectCell(BIB)}>{readingBib ? result.readings.bib.readTime.substring(11) : ''}</td>			
			
			{status()}
			{actions()}			
		</tr>
		{syncLine()}
		</>
	);
}
export default Result;
