import React,{ useState, useEffect } from 'react';
import ApplyForm from './ApplyForm';

let nextNo = 0;
let ignorePreSelect = true;
//let classObject = {id: 'any', bibend: 0, lates: []};

function ApplyAny({classObject, apply}) {


	const unselected = {startno: '', class: '', name: '', club: '', dob: '', status: 'Vakant'};
	const [selectedRow, setSelectedRow] = useState(unselected);
	const [selectedClass, setSelectedClass] = useState('');
	const [errorMsg, setErrorMsg] = useState('');


	const handleClassChange = (event) => {
		setErrorMsg('');
		setSelectedClass(event.target.value);
	}


	const onSave = (application) => {
		if (!selectedClass) {
			setErrorMsg("Klass saknas.");
			return;
		}
		console.log('save '+application.name);
		ignorePreSelect = false;

		let app = application;
		app.raceclass = selectedClass;
		if (!app.reference) {
			app.reference =  Date.now().toString();
			app.status = 'Ifylld';
			classObject.lates.push(app);
		}
		else {
			app = classObject.lates.find(a => (a.reference === application.reference));
			Object.keys(application).forEach(name => app[name] = application[name]);
			app.status = 'Uppdaterad';
		}
		localStorage.setItem(classObject.id, JSON.stringify(classObject.lates));
		apply(app, true);
		console.log('new status:' + app.status);
	}




	/**
	 * 			<div className="modal">
				<div className="modal_content">
					<span className="close" onClick={() => {ignorePreSelect = true;setShowForm(false); setSelectedRow(unselected);}}> &times;</span><br/>

				</div>
			</div>x
	 */


	const selectRow = (application) => {

		//let application = classObject.lates.find(r => (r.startno == startNo));
		if (application.status !== 'Vakant' && !application.reference) {
			return;
		}

		setSelectedRow(application);
		setSelectedClass(application.raceclass ? application.raceclass : '');
	}

	const onStartNoChange = (startno, onlyNew = false) => {

		selectedRow.startno = startno;
		setSelectedRow({...selectedRow});

		//const app = classObject.lates.find(a => (a.startno == startno));

		//selectRow(app || {startno: startno, name: '', club: '', dob: '', status: 'Vakant'});


	}

	const selectButton = (application) => {
		if (application.status !== 'Vakant' && !application.reference) {
			return;
		}
		return (<button type="button" onClick={() => selectRow(application)}>🖊</button>);
	}


	if (!classObject) {
		return null;
	}


	let storedClass = localStorage.getItem(classObject.id);
	if (storedClass) {
		storedClass = JSON.parse(storedClass);
		storedClass.forEach(app => {
			const r = classObject.lates.find(a => (a.startno == app.startno));
			if (r) {
				Object.keys(app).forEach(name => r[name] = app[name]);
			}
			else {
				classObject.lates.push(app);
			}
		});
	}

	const empties = [];
	let availableCount = 100;
	for (let i=0; i<1; i++) {
		empties.push({startno: '', name: '', club: '', dob: '', status: 'Vakant'});
	}


	return (
		<>
			<span className="errorMessage">{errorMsg}</span><br/>
			<select id="classselector" name="classselector" value={selectedClass} onChange={handleClassChange}>
				<option value="">Välj klass</option>
				<option value="21 km M">21 km M</option>
				<option value="21 km K">21 km K</option>
				<option value="21 km">21 km</option>
				<option value="10 km">10 km</option>
				<option value="5 km">5 km</option>
			</select>

			<div className="applyformcontainer">
				<ApplyForm application={selectedRow} onSave={onSave} onStartNoChange={onStartNoChange} from="0"
						   to="2000"/>
			</div>

			<div className="tableContainer">
				<table className="applyListTable">
					<thead>
					<tr key="0">
						<th>Klass</th>
						<th>Nummer</th>
						<th>Namn</th>
						<th>Klubb/Ort</th>
						<th>Födelseår</th>
						<th>Status</th>
						<th></th>
					</tr>
					</thead>
					<tbody>
					{classObject.lates.map(r => (<tr key={r.startno}
													 className={(selectedRow.startno == r.startno ? 'selectedapplication ' : ' ') + (r.status !== 'Anmäld' ? 'applyremains' : '')}
													 onClick={() => selectRow(r)}>
						<td>{r.raceclass}</td>
						<td>{r.startno}</td>
						<td>{r.name}</td>
						<td>{r.club}</td>
						<td>{r.dob}</td>
						<td>{r.status}</td>
						<td>{selectButton(r)}</td>
					</tr>))}
					{empties.map(r => (
						<tr key={r.startno} className={selectedRow.startno == r.startno ? 'selectedapplication' : ''}
							onClick={() => selectRow(r)}>
							<td>{r.raceclass}</td>
							<td>{r.startno}</td>
							<td></td>
							<td></td>
							<td></td>
							<td>{r.status}</td>
							<td>
								<button type="button" onClick={() => selectRow(r)}>🖊</button>
							</td>
						</tr>))}
					</tbody>
				</table>
			</div>
		</>
	);
}


export default ApplyAny;
