import React from 'react';

function BibReadingRow({ reading }) {
	return (
		<tr align="right"><td>{reading.pagination}</td><td>{reading.id ? reading.id : 'Registrerar...'}</td><td>{reading.readTime.substring(11)}</td><td>{reading.readData}</td>
			<td>{reading.result ? reading.result.result : ''} {reading.result && reading.result.runner ? reading.result.runner.name : ''}</td>
		</tr>
	);
}
export default BibReadingRow;
