import React from 'react';
import TimeReadingHeaderRow from './TimeReadingHeaderRow';
import TimeReadingRow from './TimeReadingRow';
import BibReadingHeaderRow from './BibReadingHeaderRow';
import BibReadingRow from './BibReadingRow';
import {TIME_READING, BIB_READING} from '../ReadingUnit/ReadingUnit';

function ReadingList({listLoading, unit, readings}) {
	console.log('renderlist called '+readings.length);
	
	const sorted = readings.sort((a,b) => b.pagination-a.pagination);
	return (
		<>
		<span>{listLoading ? 'Laddar ...' : ''} </span>
		<table className="readinglist">
			<thead>
				{unit.readingType === TIME_READING ? <TimeReadingHeaderRow /> : <BibReadingHeaderRow />}
			</thead>
			<tbody>
				{sorted.map(reading => (unit.readingType === TIME_READING ? <TimeReadingRow key={reading.key} reading={reading} /> : <BibReadingRow key={reading.key} reading={reading} />))}
			</tbody>
		</table>
		</>
	);
}
export default ReadingList;
