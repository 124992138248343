import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-regular-svg-icons'

function SignIn({setIsSignedIn, setYear, token, year, server, setServer}) {
	
	const onInputChange = (event) => {
		const token = event.target.form[0].value;
		setIsSignedIn(token);
		
		const year = event.target.form[1].value;
		setYear(year);
		
		const server = event.target.form[2].value;
		setServer(server);			
	}
	const inviteUrl = window.location.href.replace('/signin', '/invite')+'?apiKey='+token+'&year='+year+'&server='+escape(server);

	return (
		 <div>
            <form>
                <label>API-nyckel: </label>
                <input id="token" name="token" type="text" defaultValue={token ? token : ''} /><br/>
				<label>År: </label>
                <input id="year" name="year" type="text" defaultValue={year ? year : ''} /><br/>
				<label>Server (url): </label>
                <input id="server" name="server" type="text" defaultValue={server ? server : ''} /><br/>
				<button type="button" onClick={onInputChange}>OK</button>
            </form>
<p/>
	Inbjudnings-URL: {inviteUrl}
	    
         &nbsp;<button onClick={() => {navigator.clipboard.writeText(inviteUrl)}}><FontAwesomeIcon icon={faCopy} /></button>
         <p>
			<button onClick={() => {window.location.reload()}}>Ladda om appen</button> Kan användas för att läsa in en ny version av web-appen.
		 </p>
        </div>
        
	);
}

export default SignIn;