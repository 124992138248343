import { gql, useQuery } from '@apollo/client';

const SITE_URL = 'siteUrl';
const SITE_PWD = 'sitePwd';
const SITE_YEAR = 'year';
const PROXY_URL = 'proxyUrl';

const READ_CONFIG_QUERY = gql`
 query getObjectFolder($fullpath: String!)  {
  getObjectFolder(fullpath: $fullpath) {
    properties {
      ... on property_text {
        name
        text
      }
    }
  }
}
`;


const config = {};
let lastYear = 0;


function Config({ year, setSiteUrl }) {
	const { loading: loading, refetch: refetch } = useQuery(READ_CONFIG_QUERY, {
		variables: { fullpath: '/kanallopet/' + year },
		onCompleted: data => {
			data.getObjectFolder.properties.forEach(property => {
				config[property.name] = property.text;
				localStorage.setItem('config.'+property.name, property.text);
			});
			lastYear = year;
			setSiteUrl(getSiteUrl());
		},
		onError: data => {
			 const props = [SITE_URL, SITE_PWD, SITE_YEAR, PROXY_URL];
			 props.forEach(p => {
				 const v = localStorage.getItem('config.'+p);
				 if (v) {
					 config[p] = v;
				 }
			 })	
		}
	});


	if (!loading && year !== lastYear) {
		refetch();
		lastYear = year;
	}

	return null;
}

function getConfig(name) {
	return config[name];
}

function getSiteUrl() {
	return getConfig(SITE_URL);
}

function getSitePwd() {
	return getConfig(SITE_PWD);
}

function getSiteYear() {
	return getConfig(SITE_YEAR);
}

function getProxyUrl() {
	return getConfig(PROXY_URL);
}

export default Config;
export { getConfig, getSiteUrl, getSitePwd, getSiteYear, getProxyUrl, SITE_URL };
