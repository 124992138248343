import React, { useState, useEffect } from 'react';

import {
	useQuery,
	useLazyQuery,
	gql
} from "@apollo/client";

const TIME_QUERY = gql`
     query appTimeQuery  {
		  appTimeQuery {
		    app_time
		  }
		}

`;

let timeshift = 0; 

function getFormatedTime(withFragment = false) {
	const date = new Date(); 
	date.setMilliseconds(date.getMilliseconds() + timeshift);
	
	const options = {
	  year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'
	};
	if (withFragment) {
		options.fractionalSecondDigits = 1;
	}
	return new Intl.DateTimeFormat('sv-SE', options).format(date).replace(',', '.');
}


function Clock({timeShiftCallback}) {
    const [dateState, setDateState] = useState(new Date());

	const options = {
	  year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'
	};

    
	const [fetchTime, {}] = useLazyQuery(TIME_QUERY, {
		variables: { },
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'network-only',
		pollInterval: 300000,
		onCompleted: data => {
			const serverTime = new Date(data.appTimeQuery.app_time);
			timeshift = serverTime.getTime() - new Date().getTime();
			if (timeShiftCallback) {
				timeShiftCallback(timeshift);
			}
		}
					
	});
	
	useEffect(() => {
		   fetchTime();
           const interval = setInterval(() => {
				const date = new Date(); 
				date.setMilliseconds(date.getMilliseconds() + timeshift); 
				setDateState(date)
			}
			, 100);
		   return () => clearInterval(interval);
    }, []);
	

    return (
        <div className="Clock">
           {new Intl.DateTimeFormat('sv-SE', options).format(dateState)}
        </div>
    );
}

export default Clock;
export {getFormatedTime};