import React, { useState, useEffect } from 'react';
import {
	useQuery,
	useLazyQuery,
	gql,
	useMutation
} from "@apollo/client";
import Result from'./Result';
import {getSiteUrl} from "../Config";

let allResults = [];
let commingResults = [];
//let searchPagination = Number.MAX_SAFE_INTEGER;
let startNumber;
let resultId;

const BIB = 'bib';
const PRIMARY = 'primary';
const SECONDARY = 'secondary';
const TIME = 'time';
const RUNNER = 'runner';

const PLAY = 5000;
const PAUSE = 500000;

const primary = {id : 0, offset : 0, name : 'primary', current : 0};
const secondary = {id : 0, offset : 0, name : 'secondary', current : 0};
const bib = {id : 0, offset : 0, name : 'bib', current : 0};
const units = {primary: primary, secondary: secondary, bib: bib};
let searchResultPagination = 0;
//const highestResultPaginaiton = {primary: 0, secondary: 0, bib: 0};
let numberInQueue = 0;
let waitForSelectedCellCallback = null;
let lastSync = 0;
let highestDisplayedResultPagination = 0;
let onPause = false;
let globalLoadFromZero = false;


function Results({year}) {

	
	const [selectedCell, setSelectedCell] = useState({result: null, type: null});
	const [modefiedSince, setModefiedSince] = useState(0);
	const [pollInterval, setPollInterval] = useState(PLAY);
	const [loadFromZero, setLoadFromZero] = useState(false);

	globalLoadFromZero = loadFromZero;

	useEffect(() => {
		onPause = pollInterval === PAUSE;
	}, []);
	
	useEffect(() => {
	    if (waitForSelectedCellCallback) {
	      const func = waitForSelectedCellCallback;
		  waitForSelectedCellCallback = null;
		  func();
	    }
	  }, [selectedCell]);


	console.log('loading results 1');
	
	const READING_UNITS_QUERY = gql`
	 query getKanallopetReadingUnitListing($filter: String!)  {
	  getKanallopetReadingUnitListing(filter: $filter) {
	    edges {
	      node {
	        id
			name
			readingType
			primary
			paginationOffset
	      }
	    }
	  }
	}
	`;
	
	const RESULTS_QUERY = gql`
	  query getKanallopetResultListing($filter: String!) {
	  	getKanallopetResultListing(filter: $filter, sortOrder: "ASC") {
	
		  edges {
		    node {
				runner {
		            ... on object_KanallopetRunner {
		               	id
		                name
		                club
		              	startNo
						competitionClass {
							... on object_KanallopetClass {
								name
								startTime
							}
						}
		            }
		       }
			   readings {
			      ... on object_KanallopetReading {
			        id
			        pagination
			        readData
			        readTime
			        readingType
			        primary
					readingUnit
			      }
			    }
				id
			    result
			    status
			    pagination
				modificationDate
				readingsPagination
				timeFrom {
		          	... on object_KanallopetReadingUnit {
		              id
		            }
		          }
		    }
		  }
	  	}
	}
	`;
	
	
	const RUNNER_QUERY = gql`
		     query getKanallopetRunnerListing($filter: String!)  {
			  getKanallopetRunnerListing(filter: $filter) {
			    edges {
			      node {
			        id
			      }
			    }
			  }
			}
`;
	
	const UPDATE_READING_UNIT_MUTATION = gql`
	  mutation updateKanallopetReadingUnit($id: Int!, $offset: Int!)		     {
	  	updateKanallopetReadingUnit(id: $id, input: {paginationOffset: $offset}) {
		  success
	      message 
		  output {
			id
		  }
		}
	 }
	`;
	
	const UPDATE_RESULT_MUTATION = gql`
	  mutation updateKanallopetResult($id: Int!, $readingsPagination: String!, $timeFromId: Int!, $status: String!) {
	  	updateKanallopetResult(id: $id, input: {readingsPagination: $readingsPagination, timeFrom: {id: $timeFromId, type: "object"}, result: "", status: $status}) {
		  success
	      message 
		  output {
			runner {
		            ... on object_KanallopetRunner {
		               	id
		                name
		                club
		              	startNo
						competitionClass {
							... on object_KanallopetClass {
								name
								startTime
							}
						}
		            }
		       }
			   readings {
			      ... on object_KanallopetReading {
			        id
			        pagination
			        readData
			        readTime
			        readingType
			        primary
					readingUnit
			      }
			    }
				id
			    result
			    status
			    pagination
				modificationDate
				readingsPagination
				timeFrom {
		          	... on object_KanallopetReadingUnit {
		              id
		            }
		          }
		  }
		}
	 }
	`;
	
	const UPDATE_TIME_RESULT_MUTATION = gql`
	  mutation updateKanallopetResult($id: Int!, $timeResult: String!) {
	  	updateKanallopetResult(id: $id, input: {result: $timeResult}) {
		  success
	      message 
		  output {
			id
		  }
		}
	 }
	`;
	
	const UPDATE_RESULT_RUNNER_MUTATION = gql`
	  mutation updateKanallopetResult($id: Int!, $runnerId: Int!)		     {
	  	updateKanallopetResult(id: $id, input: { runner: {id: $runnerId, type: "object"}}) {
		  success
	      message 
		  output {
			id
		  }
		}
	 }
	`;
	
	const UPDATE_RESULT_STATUS_MUTATION = gql`
	  mutation updateKanallopetResult($id: Int!, $status: String!)		     {
	  	updateKanallopetResult(id: $id, input: { status: $status}) {
		  success
	      message 
		  output {
			id
		  }
		}
	 }
	`;
	
	const CREATE_RESULT_MUTATION = gql`
	  mutation createKanallopetResult($key: String!, $path: String!, $pagination: Int!, $readingsPagination: String!) {
	  	createKanallopetResult(key: $key, published: true, path: $path, input: {pagination: $pagination, readingsPagination: $readingsPagination, status: "new"}) {
		   success
	      message 
		  output {
			runner {
		            ... on object_KanallopetRunner {
		               	id
		                name
		                club
		              	startNo
						competitionClass {
							... on object_KanallopetClass {
								name
								startTime
							}
						}
		            }
		       }
			   readings {
			      ... on object_KanallopetReading {
			        id
			        pagination
			        readData
			        readTime
			        readingType
			        primary
					readingUnit
			      }
			    }
				id
			    result
			    status
			    pagination
				modificationDate
				readingsPagination
				timeFrom {
		          	... on object_KanallopetReadingUnit {
		              id
		            }
		          }
		  }
		}
	 }
	`;
	
	const DELETE_RESULT_MUTATION = gql`
	  mutation deleteKanallopetResult($id: Int!) {
	  	deleteKanallopetResult(id: $id) {
		  success
	      message 
		}
	 }
	`;
	
	
	const createResultObject = (result) => {
		const rp = JSON.parse(result.readingsPagination); 
		return {id: result.id, result: result.result, pagination: result.pagination, status: result.status, 
			readingsPagination: rp, rpObject: getReadingsPaginationObject(rp),
			runner: result.runner, readings: getReadingsObject(result.readings), modificationDate: result.modificationDate,
			timeFrom: result.timeFrom };
	}
	
	
	
	const [callUpdateReadingUnit, { error: rmError }] = useMutation(UPDATE_READING_UNIT_MUTATION, { 
		variables: { }, 
		onCompleted: data => {
			console.log(data.updateKanallopetReadingUnit.output.id+' '+data.updateKanallopetReadingUnit.message);			
		},
		onError: data => {
			console.log('Updating reading unit failed');			
		}
	});
	
	const [callUpdateResult, { error: reuError }] = useMutation(UPDATE_RESULT_MUTATION, { 
		variables: { timeFromId: primary.id }, 
		onCompleted: data => {
			console.log(data.updateKanallopetResult.output.id+' ORIGINAL '+data.updateKanallopetResult.message);
			const newResult = createResultObject(data.updateKanallopetResult.output);
			const result = allResults.find(r => r.id == newResult.id);
			
			const returnedTarget = Object.assign(result, newResult);
			//allResults = allResults.map(obj => result.id == obj.id ? result : obj);
			//setModefiedSince(result.modificationDate > modefiedSince ? result.modificationDate : modefiedSince);
			
			setModefiedSince(new Date().getTime());
			
			//arr1.map(obj => arr2.find(o => o.id === obj.id) || obj);
		},
		onError: data => {
			console.log('Updating resultfailed');			
		}
	});
	
	const [callUpdateTimeResult, { error: terError }] = useMutation(UPDATE_TIME_RESULT_MUTATION, { 
		variables: { }, 
		onCompleted: data => {
			console.log(data.updateKanallopetResult.output.id+' time result '+data.updateKanallopetResult.message);
		},
		onError: data => {
			console.log('Updating time failed');			
		}
	});
	
	
	const [callUpdateResultRunner, { error: ruerError }] = useMutation(UPDATE_RESULT_RUNNER_MUTATION, { 
		variables: { }, 
		onCompleted: data => {
			console.log(data.updateKanallopetResult.output.id+' runner '+data.updateKanallopetResult.message);
		},
		onError: data => {
			console.log('Updating runner failed');			
		}
	});
	
	const [callUpdateResultStatus, { error: rstError }] = useMutation(UPDATE_RESULT_STATUS_MUTATION, { 
		variables: { }, 
		onCompleted: data => {
			console.log(data.updateKanallopetResult.output.id+' status '+data.updateKanallopetResult.message);
		},
		onError: data => {
			console.log('Updating status failed');			
		}
	});
	
	
	const [callCreateResult, { error: recError }] = useMutation(CREATE_RESULT_MUTATION, { 
		variables: { }, 
		onCompleted: data => {
			console.log(data.createKanallopetResult.output.id+' '+data.createKanallopetResult.message);		
			const newResult = createResultObject(data.updateKanallopetResult.output);
			const result = allResults.find(r => r.id == newResult.id);
			
			const returnedTarget = Object.assign(result, newResult);
			//allResults = allResults.map(obj => result.id == obj.id ? result : obj);
			//setModefiedSince(result.modificationDate > modefiedSince ? result.modificationDate : modefiedSince);
			setModefiedSince(new Date().getTime());		
		},
		onError: data => {
			console.log('Updating reading unit failed');		
			
		}
	});
	
	const [callDeleteResult, { error: dError }] = useMutation(DELETE_RESULT_MUTATION, { 
		variables: { }, 
		onCompleted: data => {
			console.log(data.deleteKanallopetResult.success+' '+data.deleteKanallopetResult.message);	
			setModefiedSince(new Date().getTime());		
		},
		onError: data => {
			console.log('Updating reading unit failed');			
		}
	});
	
	
	const updateAllReadingsPagination = (result, affectedPagination, reservedBibPagniations) => {
		const resultsToUpdate = allResults.filter(res => {
			return res.pagination >= result.pagination;
		}).sort((a,b) => (a.pagination - b.pagination));
		
		const resultsToClear = allResults.filter(res => {
			return res.pagination >= affectedPagination*100 && res.pagination < result.pagination;
		}).sort((a,b) => (a.pagination - b.pagination));

		resultsToUpdate.forEach((r) => {
			const pagination = r.pagination / 100;
			r.rpObject.primary.pagination = pagination - primary.offset;
			r.rpObject.secondary.pagination = pagination - secondary.offset;
			r.rpObject.bib.pagination = pagination - bib.offset;
			
			callUpdateResult({ variables : { id: parseInt(r.id), readingsPagination: JSON.stringify(r.readingsPagination),
							      			 timeFromId: r.timeFrom ? parseInt(r.timeFrom.id) : primary.id, status: r.status}});

		});
		
		resultsToClear.forEach((r) => {
			r.rpObject.primary.pagination = r.readings.primary ? r.readings.primary.pagination : 0;
			r.rpObject.secondary.pagination = r.readings.secondary ? r.readings.secondary.pagination : 0;
			if (r.pagination > reservedBibPagniations * 100) {
				r.rpObject.bib.pagination = r.readings.bib ? r.readings.bib.pagination : 0;
			}
			
			callUpdateResult({ variables : { id: parseInt(r.id), readingsPagination: JSON.stringify(r.readingsPagination),
							      			 timeFromId: r.timeFrom ? parseInt(r.timeFrom.id) : primary.id, status: r.status}});
		});
	}



	
	const updateReadingsPagination = (result, unit, diff) => {
		
		
		let resultsToUpdate = allResults.filter(res => {
			return res.pagination >= result.pagination;
		}).sort((a,b) => a.pagination - b.pagination);
		
		let isSyncedAbove = lastSync > result.pagination;
		const newPaginations = [];
		for (let i=0; i<resultsToUpdate.length; i++) {
			var current = resultsToUpdate[i];
			if (current.id != result.id && current.status.includes('synced')) {
				break;
			}
			let replaceIndex = i + diff;
			
			if (replaceIndex == -1) {
				newPaginations.push(0);
			}
			else if (replaceIndex == resultsToUpdate.length) {
				newPaginations.push(isSyncedAbove ? 0 : current.pagination/100 - unit.offset);
			}
			else {
				const next = resultsToUpdate[replaceIndex];
				if (next.status.includes('synced')) {
					newPaginations.push(0);
					break;
				}
				const nextReadingPagination = next.rpObject[unit.name]; //next.readingsPagination.find(rp => rp.id === unit.id);
				newPaginations.push(nextReadingPagination.pagination);
			}
		}
		newPaginations.forEach((np, i)  => {
			const res = resultsToUpdate[i];
			const readingPagination = res.rpObject[unit.name]; //res.readingsPagination.find(rp => rp.id === unit.id);
			readingPagination.pagination = np;
			callUpdateResult({ variables : { id: parseInt(res.id), readingsPagination: JSON.stringify(res.readingsPagination),
							      			 timeFromId: res.timeFrom ? parseInt(res.timeFrom.id) : primary.id, status: res.status}});
		});

	}
	
	const removeResult = () => {
		

		secondary.offset++;
		callUpdateReadingUnit({ variables : { id: secondary.id, offset: secondary.offset }});


		bib.offset++;
		callUpdateReadingUnit({ variables : { id: bib.id, offset: bib.offset }});

		
		const units = [secondary.id, bib.id];
		const result = selectedCell.result;
		
		const resultsToUpdate = allResults.filter(res => {
			return res.pagination >= result.pagination;
		}).sort((a,b) => b.pagination - a.pagination);
		const max = resultsToUpdate.length - 1;
		const highest = {secondary: 0, bib: 0};
		
		resultsToUpdate.forEach((r, i, arr) => {
			if (i === max) {
				return;
			}
			units.forEach(unitId => {
				const next = arr[i+1];
				const readingPagination = r.readingsPagination.find(rp => rp.id === unitId);

				const nextReadingPagination = next.readingsPagination.find(rp => rp.id === unitId);
				
				readingPagination.pagination = nextReadingPagination ? nextReadingPagination.pagination : null;				
			});
			const secondaryRP = r.readingsPagination.find(rp => rp.id === secondary.id);
			const bibRP = r.readingsPagination.find(rp => rp.id === bib.id);
			highest.secondary = secondaryRP && secondaryRP.pagination > highest.secondary ? secondaryRP.pagination : highest.secondary;
			highest.bib = bibRP && bibRP.pagination > highest.bib ? bibRP.pagination : highest.bib;

			callUpdateResult({ variables : { id: parseInt(r.id), readingsPagination: JSON.stringify(r.readingsPagination),
				timeFromId: r.timeFrom ? parseInt(r.timeFrom.id) : primary.id, status: r.status }});
		});
		//highestResultPaginaiton.secondary = highest.secondary;
		//highestResultPaginaiton.bib = highest.bib;
		
		callDeleteResult({ variables : { id: parseInt(result.id)}});
		allResults = allResults.filter(r => r.id != result.id);
		refetchAll(result.pagination, Math.min(highest.secondary, highest.bib));
		
	}
	
	const addResult = () => {
		

		secondary.offset--;
		callUpdateReadingUnit({ variables : { id: secondary.id, offset: secondary.offset }});

		bib.offset--;
		callUpdateReadingUnit({ variables : { id: bib.id, offset: bib.offset }});

		
		const units = [secondary.id, bib.id];
		const result = selectedCell.result;
		
		const resultsToUpdate = allResults.filter(res => res.pagination >= result.pagination).sort((a,b) => a.pagination - b.pagination);
		const prevResult = allResults.find(res => res.pagination < result.pagination);
		const newPage = Math.round((result.pagination + (prevResult ? prevResult.pagination : 0)) / 2);
		const readingsPagination = [{"id":primary.id,"type":"time","pagination":null},{"id":bib.id,"type":"bib","pagination":null},{"id":secondary.id,"type":"time","pagination":null}];
		const newResult = {id: 'none', readings: {}, status: 'new', pagination: newPage, readingsPagination: readingsPagination};
		resultsToUpdate.unshift(newResult);

		resultsToUpdate.forEach((r, i, arr) => {
			units.forEach(unitId => {
				const next = arr[i+1];
				const readingPagination = r.readingsPagination.find(rp => rp.id === unitId);
				if (next) {					
					const nextReadingPagination = next.readingsPagination.find(rp => rp.id === unitId);
					
					readingPagination.pagination = nextReadingPagination ? nextReadingPagination.pagination : null;	
				}
				else {
					readingPagination.pagination++;
				}
							
			});
			if (i === 0) {
				// create result
				const newPath = '/kanallopet/'+year+'/results';
				callCreateResult({ variables : { path: newPath, key: 'result_'+newPage, pagination: newPage, readingsPagination: JSON.stringify(r.readingsPagination) }});
			}
			else  {
				callUpdateResult({ variables : { id: parseInt(r.id), readingsPagination: JSON.stringify(r.readingsPagination), 
					timeFromId: r.timeFrom ? parseInt(r.timeFrom.id) : primary.id, status: r.status }});	
			}
			r.rpObject = getReadingsPaginationObject(r.readingsPagination);
		});
		allResults.push(newResult);
		allResults = allResults.sort((a,b) => b.pagination-a.pagination);
		//modefiedSince = 0;
		refetchAll(newPage);
	}
	
	const remove = () => {
		console.log('Called remove on '+JSON.stringify(selectedCell));
		
		const unit = units[selectedCell.type];
		if (selectedCell.result.pagination >= lastSync) {
			unit.offset--;
			callUpdateReadingUnit({ variables : { id: unit.id, offset: unit.offset }});
		}
		updateReadingsPagination(selectedCell.result, unit, 1);
		 		
		setSelectedCell({result: null, type: null});
	}

	const add = () => {
		console.log('Called add on '+JSON.stringify(selectedCell));
		
		const unit = units[selectedCell.type];
		if (selectedCell.result.pagination >= lastSync) {
			unit.offset++;
			callUpdateReadingUnit({ variables : { id: unit.id, offset: unit.offset }});
		}
		updateReadingsPagination(selectedCell.result, unit, -1);
		
	}

	const actionUseTime = () => {
		console.log('Called actionUseTime on '+JSON.stringify(selectedCell));
		const unit = units[selectedCell.type];
		selectedCell.result.timeFrom = {id: unit.id, type: 'object'};

		callUpdateResult({ variables : { id: parseInt(selectedCell.result.id),
				readingsPagination: JSON.stringify(selectedCell.result.readingsPagination),
				timeFromId: parseInt(selectedCell.result.timeFrom.id), status: selectedCell.result.status }
		});
	}
	
	const editPagination  = () => {
		const unit = units[selectedCell.type];
		let res = selectedCell.result;
		const readingPagination = res.rpObject[unit.name];
		const pagination = prompt('Ange paginering', readingPagination.pagination || '');
		if (!pagination || isNaN(pagination)) {
			return;
		}
		
		readingPagination.pagination = pagination;
		callUpdateResult({ variables : { id: parseInt(res.id), readingsPagination: JSON.stringify(res.readingsPagination),
						   timeFromId: res.timeFrom ? parseInt(res.timeFrom.id) : primary.id, status: res.status}});
		refetchAll(res.pagination);
	}
	
	const edit = (cell) => {
		
		
		if (!cell) {
			cell = selectedCell;
		}
		
		console.log('Called edit on '+JSON.stringify(cell));
		
		switch (cell.type) {
			case TIME:
				const timeResult = prompt('Ange löptid', cell.result.result || '');
				if (timeResult) {
					const fullPattern = /^(\d{1,2}):(\d{2}):(\d{2})$/;
					const shortPattern = /^(\d{1,2}):(\d{2})$/;
					if (!timeResult.match(fullPattern) && !timeResult.match(shortPattern)) {
						alert("Tiden ges på formatet mm:ss eller hh:mm:ss");
						return;
					}
					cell.result.result = timeResult;
					callUpdateTimeResult({variables: {id: parseInt(cell.result.id), timeResult: timeResult}});
				}
				break;
			case PRIMARY:
			case SECONDARY:		
			case BIB:
				editPagination();		
				break;
			case RUNNER:
				startNumber = prompt('Ange startnummer');				
				if (startNumber) {
					resultId = parseInt(cell.result.id);
					refetchGetRunner({ filter: '{"o_path": {"$like" : "/kanallopet/'+year+'/applications/%"}, "startNo" : {"$like" : "'+startNumber+'"}}' });
				}				
				break;
			default:
				break;
		}	
	}
	
	const setOk = (cell) => {
		
		if (!cell) {
			cell = selectedCell;
		}
		
		console.log('Called setOk on '+JSON.stringify(cell));
		
		if (!cell.result.result) {
			alert('Det krävs en tid för att godkänna ett resultat.');
			return;
		}
		
		if (!cell.result.runner) {
			alert('Det krävs en löpare för att godkänna ett resultat.');
			return;
		}
		cell.result.status = cell.result.status.includes('synced') ? 'synced;ok' : 'ok';
		
		callUpdateResultStatus({variables: {id: parseInt(cell.result.id), status: cell.result.status}});		
	}
	
	
	const actionTrigged = (type, cell, isCallback = false) => {
		console.log('trigged '+type);
		if (cell && !isCallback) {
			waitForSelectedCellCallback = function () {actionTrigged(type, cell, true);};
			setSelectedCell(cell);
			return;
		}
		switch(type) {
			case 'remove':
				remove();
				break;
			case 'add':
				add();
				break;
			case 'useTime':
				actionUseTime();
				break;
			case 'edit':
				edit(cell);
				break;
			case 'setOk':
				setOk(cell);
				break;
			default:
				console.log('Unknown type');
		}		
	}
	
	const action = (type, icon, title, text = '', cell) => {	

		const currentCell = cell ? cell : selectedCell;
		const cellType = currentCell ? currentCell.type : '';
			
		if (type === 'useTime') {
			if ([PRIMARY, SECONDARY, BIB].indexOf(cellType) < 0 || !selectedCell) {
				return null;
			}
			if(!selectedCell.result.readings[cellType]) {
				return null;
			}
		}
		if (type === 'remove' && [PRIMARY, SECONDARY, BIB].indexOf(cellType) < 0 ) {
			return null;
		}
		if (type === 'add' && [PRIMARY, SECONDARY, BIB].indexOf(cellType) < 0 ) {
			return null;
		}
		if (type === 'setOk' && (!currentCell || !currentCell.result || !currentCell.result.result || !currentCell.result.runner || 
			(currentCell.result.status && currentCell.result.status.includes('ok')))) {
			return null;
		}


		return (<><button title={title} onClick={() => actionTrigged(type, cell) }>{icon}{text}</button>{text ? ' ' : ''}</>);
	}
	
	const actions = () => {
		if (!selectedCell.result) {
			return null;
		}
		return (
			<>
				{action('remove', '❌', 'Ta bort', 'Ta bort')}
				{action('add', '➕', 'Skjut in', 'Skjut in')}
				{action('useTime', '⬅', 'Använd tid', 'Använd tid')}
				{action('edit', '🖊', 'Ändra.', 'Ändra')}
				{action('setOk', '✅', 'Godkänn', 'Godkänn')}
			</>
		);
	}
	
	const getReadingsObject = readings  => {		
		const object = {};
		if (readings) {
			object.primary = readings.find(reading => reading.readingType === 'time' && reading.primary);
			object.secondary = readings.find(reading => reading.readingType === 'time' && !reading.primary);
			object.bib = readings.find(reading => reading.readingType === 'bib' && reading.primary);
		}
		return object;
	}
	
	const getReadingsPaginationObject = readingsPagination  => {	
		const object = {};
		if (readingsPagination) {
			object.primary = readingsPagination.find(rp => rp.id === primary.id);
			object.secondary = readingsPagination.find(rp => rp.id === secondary.id);
			object.bib = readingsPagination.find(rp => rp.id === bib.id);
		}
		return object;

	}

	const path = '/kanallopet/'+year+'/results/%';
	
	const { loading: uLoading, error: uError, data: uData } = useQuery(READING_UNITS_QUERY, {
			variables: { filter: '{"o_path": {"$like" : "/kanallopet/'+year+'/readings/"}}' },
	});
	
	const [getRunner, { loading: runLoading, error: runError, data: runData, refetch: refetchGetRunner }] = useLazyQuery(RUNNER_QUERY, {
			variables: { filter: '{"o_path": {"$like" : "/kanallopet/'+year+'/applications/%"}, "startNo" : {"$like" : "'+startNumber+'"}}' },
			onCompleted: data => {
					console.log('getKanallopetRunnerListing returned ');
					const edge = data.getKanallopetRunnerListing.edges[0];	
					if (!edge) {
						alert("Ingen löpare med numret hittades.");
					}
					else {
						callUpdateResultRunner({variables: {id: resultId, runnerId: parseInt(edge.node.id) },
					    onCompleted: data => {
							console.log(data.updateKanallopetResult.output.id+' SPECIAL 2 '+data.updateKanallopetResult.message);	
							refetchAll(0);
						}});
					}	
		
				}
	});

	//const [getResults, { loading, error, data, refetch, startPolling, stopPolling }] = useLazyQuery(RESULTS_QUERY, {
	const { loading, error, data, refetch, startPolling, stopPolling } = useQuery(RESULTS_QUERY, {
		variables: {filter: '{"o_path": {"$like" : "'+path+'"}, "pagination": {"$gt" :"'+searchResultPagination+'"}}'  },
		pollInterval: pollInterval,
		onCompleted: data => {
			console.log('results query response');	
			
			const edges = data.getKanallopetResultListing.edges;/*
			const fetchedResults = edges.map(result => {const rp = JSON.parse(result.node.readingsPagination); return {id: result.node.id, result: result.node.result, pagination: result.node.pagination, status: result.node.status, 
			readingsPagination: rp, rpObject: getReadingsPaginationObject(rp),
			runner: result.node.runner, readings: getReadingsObject(result.node.readings), modificationDate: result.node.modificationDate,
			timeFrom: result.node.timeFrom };});
			*/
			
			const fetchedResults = edges.map(result => {return createResultObject(result.node)});
		
			const newResults = [];
			allResults.forEach(result => {
				if (!fetchedResults.find(r => {return r.pagination === result.pagination})) {
					newResults.push(result);
				}
			});
			newResults.push(...fetchedResults);
			allResults = newResults.sort((a,b) => b.pagination-a.pagination);
		
			allResults.forEach(result => {		
				primary.current = Math.max(result.readings.primary ? result.readings.primary.pagination : 0, primary.current);
				secondary.current = Math.max(result.readings.secondary ? result.readings.secondary.pagination : 0, secondary.current);
				bib.current = Math.max(result.readings.bib ? result.readings.bib.pagination : 0, bib.current);
				
				searchResultPagination = (primary.current + primary.offset) * 100;
				searchResultPagination = Math.min((secondary.current + secondary.offset) * 100, searchResultPagination);
				searchResultPagination = Math.min((bib.current + bib.offset) * 100, searchResultPagination);
				
				/*
				highestResultPaginaiton.primary = Math.max(result.readingsPagination.find(rp => rp.id === primary.id).pagination, highestResultPaginaiton.primary);
				highestResultPaginaiton.secondary = Math.max(result.readingsPagination.find(rp => rp.id === secondary.id).pagination, highestResultPaginaiton.secondary);
				highestResultPaginaiton.bib = Math.max(result.readingsPagination.find(rp => rp.id === bib.id).pagination, highestResultPaginaiton.bib);
				console.log(JSON.stringify(highestResultPaginaiton));
				*/
				highestDisplayedResultPagination = result.status === 'created' ? highestDisplayedResultPagination : Math.max(result.pagination, highestDisplayedResultPagination);
			});
			
			numberInQueue = primary.current + primary.offset - (bib.current + bib.offset);
			
			//let modified = modefiedSince;
			allResults.forEach(result => {
				//modified = result.modificationDate > modified ? result.modificationDate : modified;
				lastSync = result.status.includes('synced') && result.pagination > lastSync ?  result.pagination : lastSync;
			});
			setModefiedSince(new Date().getTime());
		},
	});

	useEffect(() => {
		const interval = setInterval(() => {
				if (!onPause) {
					console.log('refetch ');
					//refetch();
					refetchAll(globalLoadFromZero ? 0 : null);
				}
			}
			, pollInterval);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		let t = pollInterval;
		const interval = setInterval(() => {

			if (!onPause) {
				t = t + (t % 2 === 0 ? 1 : -1);
				setPollInterval(t);
			}
		}, 1000);
		return () => clearInterval(interval);
	}, []);
	
	//const [getReadings, { loading: rLoading, error: rError, data: rData }] = useLazyQuery(READING_QUERY);

		
	const refetchAll = (resultPagination = null, readingPagination = null) => {
		console.log("refetchAll");
		//console.log(JSON.stringify(highestResultPaginaiton));
		//searchPagination = Math.min(highestResultPaginaiton.bib ? highestResultPaginaiton.bib : Number.MAX_SAFE_INTEGER, highestResultPaginaiton.secondary ? highestResultPaginaiton.secondary : Number.MAX_SAFE_INTEGER);
		//console.log(searchPagination);
		//const defaultFilter = {filter: '{"o_path": {"$like" : "/kanallopet/'+year+'/readings/%"}, "pagination": {"$gt" :"'+searchPagination+'"}}'};
		if (resultPagination === null) {
			resultPagination = (primary.current + primary.offset) * 100;
			resultPagination = Math.min((secondary.current + secondary.offset) * 100, resultPagination);
			resultPagination = Math.min((bib.current + bib.offset) * 100, resultPagination);
		}

		searchResultPagination = resultPagination;
		refetch({filter: '{"o_path": {"$like" : "'+path+'"}, "pagination": {"$gte" :"'+searchResultPagination+'"}}'  });
	}
	

	if (uLoading) {
		console.log('loading 2');
		return (
			<div>
				Laddar resultat ...
			</div>
		);
	}
	if (error) {
		console.log('error loading results');
		return `Error! ${error}`;
	}
	if (uError) {
		console.log('error loading reading units');
		return `Error! ${uError}`;
	}
	if (uData && !primary.id) {
		uData.getKanallopetReadingUnitListing.edges.forEach(edge => {
			if (edge.node.readingType === 'time' && edge.node.primary) {
				primary.id = parseInt(edge.node.id);
				primary.offset = edge.node.paginationOffset;
			}
			else if (edge.node.readingType === 'time') {
				secondary.id = parseInt(edge.node.id);
				secondary.offset = edge.node.paginationOffset;
			}
			else if (edge.node.readingType === 'bib') {
				bib.id = parseInt(edge.node.id);
				bib.offset = edge.node.paginationOffset;
			}
		});
	}

	
	const togglePause = () => {
		const isPaused = onPause;
		setPollInterval(onPause ? PLAY : PAUSE);
		onPause = !onPause;
		if (isPaused) {
			refetchAll();
		}
	}
	
	const setSyncPoint = () => {
		const inQueue = prompt('Inga löpare får gå imål eller skannas när en synkpunkt sätts.\n Ange antal löpare i kö till skanningen:', numberInQueue);
		if (inQueue === null) {
			return;
		}
		if (isNaN(inQueue)) {
			alert(inQueue + " är inte ett tal");
			return;
		} 

		
		const nextPagination =  Math.max(primary.current + primary.offset, secondary.current + secondary.offset, bib.current + bib.offset + parseInt(inQueue)) + 1;
		const affectedPagination = Math.min(primary.current + primary.offset, secondary.current + secondary.offset, bib.current + bib.offset + parseInt(inQueue)) + 1;
		const reservedBibPagniations = bib.current + bib.offset + parseInt(inQueue);
		
		primary.offset = nextPagination - (primary.current + 1);
		secondary.offset = nextPagination - (secondary.current + 1);
		bib.offset  = nextPagination - (bib.current + 1 + parseInt(inQueue));
		
		const syncedResult = allResults.find(r => {return r.pagination === nextPagination*100});
		lastSync = syncedResult.pagination;
		
		callUpdateReadingUnit({ variables : { id: primary.id, offset: primary.offset }});
		callUpdateReadingUnit({ variables : { id: secondary.id, offset: secondary.offset }});
		callUpdateReadingUnit({ variables : { id: bib.id, offset: bib.offset }});
		
		if (!syncedResult.status.includes('synced')) {
			syncedResult.status = 'synced';
			//callUpdateResultStatus({variables: {id: parseInt(syncedResult.id), status: syncedResult.status}});
			updateAllReadingsPagination(syncedResult, affectedPagination, reservedBibPagniations);
		}		
		setModefiedSince(new Date().getTime());
	}
	
	const createResultManually = () => {
		const cont = window.confirm("Ett tomt resultat kommer skapas längst ner i listan.");
		if (!cont) {
			return;
		}
		const newPage = allResults.length > 0 ? allResults[allResults.length -1].pagination -1 : 99;
		const readingsPagination = [{"id":primary.id,"type":"time","pagination":null},{"id":bib.id,"type":"bib","pagination":null},{"id":secondary.id,"type":"time","pagination":null}];
		const newResult = {id: 'none', readings: {}, status: 'new', pagination: newPage, readingsPagination: readingsPagination};
		const newPath = '/kanallopet/'+year+'/results';
		allResults.push(newResult);
		callCreateResult({ variables : { path: newPath, key: 'result_'+newPage, pagination: newPage, readingsPagination: JSON.stringify(newResult.readingsPagination) }});
				
		
		
		//allResults = allResults.sort((a,b) => b.pagination-a.pagination);
		//modefiedSince = 0;
		//refetchAll(newPage);
		
	    //const element = document.getElementById('resultstable');
	    const element = document.scrollingElement || document.body
	    element.scrollTop = element.scrollHeight;
	}
	
	console.log('render:'+modefiedSince);
	return (
		<>
		<p><button title="Uppdatera" onClick={() => {refetchAll(0);}}>🔄</button><span className="buttonLabel">Uppdatera</span> Kö till skanning <span className="numberInQueue">{numberInQueue}</span></p>
		<button title={!onPause ? "Pause av hämtning" : "Återuppta hämntning"} onClick={() => {togglePause();}}>{!onPause ? "⏸" : "▶"}</button>
		&nbsp;<button title="Sätt synkpunkt" onClick={() => {setSyncPoint();}}>Synkpunkt</button>&nbsp;
		<button title="Skapa resultat manuellt" onClick={() => {createResultManually();}}>Skapa resultat</button>&nbsp;
		{actions()} 
		<table id="resultstable">
			<thead>
				<tr><th>Pag.</th><th>Id</th><th>Löptid</th><th colSpan="3">Löpare</th><th colSpan="2">Primär tid ({primary.offset})</th><th colSpan="2">Sekundär tid ({secondary.offset})</th><th></th><th colSpan="2">Startnummer ({bib.offset})</th><th>Status</th><th></th></tr>
			</thead>
			<tbody>
				{commingResults.map(result => ( <Result key={result.id} result={result} selectedCell={selectedCell} setSelectedCell={setSelectedCell} action={action} primary={primary} secondary={secondary} bib={bib}/> ))}
				{allResults.map(result => ( result.pagination > highestDisplayedResultPagination ? null : <Result key={result.id} result={result} selectedCell={selectedCell} setSelectedCell={setSelectedCell} action={action} primary={primary} secondary={secondary} bib={bib} /> ))}
			</tbody>
		</table>
			<button title="Toggla ladda från" onClick={() => {setLoadFromZero(!loadFromZero);}}>Ladda {loadFromZero ? 'senaste' : 'alla'}</button>&nbsp;Laddar nu  {loadFromZero ? 'alla' : 'senaste'}
		</>
	);
}

export default Results;
export {BIB, PRIMARY, SECONDARY, TIME, RUNNER};
