import React, { useState } from 'react';
import ErrorMessage from '../ErrorMessage';
import { gql, useQuery, useMutation } from '@apollo/client';

const UNIT_STORAGE_KEY = 'reading-unit';

const READING_UNITS_QUERY = gql`
 query getKanallopetReadingUnitListing($filter: String!)  {
  getKanallopetReadingUnitListing(filter: $filter) {
    edges {
      node {
        id
		name
		readingType
		primary
      }
    }
  }
}
`;

const REGISTER_READING_UNIT_MUTATION = gql`
 mutation RegisterReadingUnit($key: String!, $path: String!, $readingType: String!, $primary: Boolean!, $name: String!) {
  createKanallopetReadingUnit(key: $key, published: true, path: $path, input: {name: $name, readingType: $readingType, primary: $primary}) {
    success
    message
    output {
      id
	  key
      name
	  readingType
	  primary
    }
  }
}
`;
const TIME_READING = 'time';
const BIB_READING = 'bib';

function getReadingUnit() {
	const unitJson = localStorage.getItem(UNIT_STORAGE_KEY);
	return JSON.parse(unitJson !== 'undefined' && unitJson !== 'null' ? unitJson : null);
}

function ReadingUnit({year}) {
	const storedUnit = getReadingUnit();

	const [readingUnits, setReadingUnits] = useState([]);
	const [selectedUnitId, setSelectedUnitId] = useState(storedUnit ? storedUnit.id : 0);
	
	const getUnit = id => {
		const res = readingUnits.find(u => u.id == id);
		return res;
	}
	
	const { loading: lLoading, error: lError, data: lData } = useQuery(READING_UNITS_QUERY, {
			variables: { filter: '{"o_path": {"$like" : "/kanallopet/'+year+'/readings/"}}' },
		});

		if (lData && !readingUnits.length) {
			const list = lData.getKanallopetReadingUnitListing.edges.map(el => ({id: parseInt(el.node.id), name: el.node.name, readingType: el.node.readingType, primary: el.node.primary}));
			if (list.length) {
				setReadingUnits(list);
			}
		}
	
	const assign = (event) => {
		const selectedId = event.target.form[0].value;
		console.log('assign to unit '+selectedId);
		localStorage.setItem(UNIT_STORAGE_KEY, JSON.stringify(getUnit(selectedId)));
	}
	
	const [callMutation, { loading: rLoading, error: rError }] = useMutation(REGISTER_READING_UNIT_MUTATION, { 
		variables: { path: '/kanallopet/'+year+'/readings' }, 
		onCompleted: data => {
			console.log('register unit competed');
			const unit = data.createKanallopetReadingUnit.output;
			unit.id = parseInt(unit.id);
			localStorage.setItem(UNIT_STORAGE_KEY, JSON.stringify(unit));
			readingUnits.push(unit);	
			setReadingUnits(readingUnits);
		}
	});
	
	
	const register = (event) => {
		console.log('register unit');
		const name = event.target.form[0].value;
		const type = event.target.form[1].value;
		const primary = event.target.form[2].checked;
		const key = name+'_'+type;
		
		const unit = { key: key, name: name, readingType: type, primary: primary };
	
		callMutation({ variables: unit });	
		setReadingUnits([]);
	}
	
	const changeUnit = (event) => {
		console.log('selected unit '+event.target.value)
		setSelectedUnitId(event.target.value);
	}
	
	const clearPaginations = () => {
		const doIt = window.confirm("Varning!, detta kommer tömma det lokalt sparade värdet för enheternas paginering.");
		if (doIt) {
			readingUnits.forEach(unit => {
				const key = 'unit-pagination-'+unit.id;
				localStorage.removeItem(key);
			});
		}
	}

	return (
		 <div>
			<h2>Registrera enhet</h2>
			<ErrorMessage message={lError ? lError.message : ''} />
			<ErrorMessage message={rError ? rError.message : ''} />
			<span>{lLoading ? 'Läser in ...' : '' }</span>
			<form>
				<label>Koppla upp som enhet: </label>
				<select value={selectedUnitId} onChange={changeUnit}>
				 	 <option value="0">Välj</option>
					 {readingUnits.map(unit => (<option key={unit.id} value={unit.id} >{unit.name}</option>))}
				</select>
				 <br/>
				<button type="button" onClick={assign}>Koppla</button><br/>
			</form>
			<br/>
			<span>{rLoading ? 'Registrerar enhet ...' : '' }</span>
            <form>
			   <span>Registrera ny enhet:</span><br/>
			   <label>Namn: </label> <input id="name" /><br/>
			   <label>Type: </label>
				<select>
				 	 <option value="time">Måltid</option>
					 <option value="bib">Nummerlapp</option>
				</select><br/>
				<label>Primär: </label>
				<input type="checkbox" value="checked"/><br/>
				<button type="button" disabled={rLoading} onClick={register}>Registrera</button><br/>
            </form>
			<br/>
			<button onClick={clearPaginations}>Töm paginering</button>
        </div>
	);
}
export default ReadingUnit;
export {getReadingUnit, TIME_READING, BIB_READING};
