import React,{ useState } from 'react';
import {
	useQuery,
	gql,
} from "@apollo/client";
import { getSiteUrl, getSitePwd, getProxyUrl, getSiteYear } from '../Config' 
import moment from 'moment';
import { printFormat, compare } from './ResultUtil';

let modefiedSince = 0;
let allResults = [];
let json = null;

	



function LiveExport({year}) {


	const RESULTS_QUERY = gql`
	  query getKanallopetResultListing($filter: String!)		     {
	  	getKanallopetResultListing(filter: $filter, sortOrder: "ASC") {
	
		  edges {
		    node {
				runner {
		            ... on object_KanallopetRunner {
		               	key
		                name
		                club
		              	startNo
						competitionClass {
							... on object_KanallopetClass {
								name
							}
						}
		            }
		       }
				id
			    result
			    status
			    pagination
				modificationDate
		  }
	  	}
      }
	}
	`;
	

	const [sent, setSent] = useState('');
	

	const path = '/kanallopet/'+year+'/results/%';
	
	const sendResults = (data) => {
		setSent(moment().format('YYYY-MM-DD HH:mm:ss'));
		
		let url = getSiteUrl() + 'anmalan/admin' + getSitePwd() + '/uploadresults.php?year='+getSiteYear();
		const proxyUrl = getProxyUrl();
		if (proxyUrl && proxyUrl.startsWith('http')) {
			url = proxyUrl+'?target='+escape(url);
		} 
		
		var xhttp = new XMLHttpRequest();
		xhttp.onreadystatechange = function() {
		    if (this.readyState > 3 && this.status == 200) {
		      console.log(this.responseText);
		    }
		    else {
		    	console.log('Misslyckades:'+this.status +':' +this.readyState +':' + this.responseText);
		    }
	    };
		xhttp.open('POST', url, true);
		xhttp.setRequestHeader('Content-Type', 'application/json');
		xhttp.send(data);
	}
	
	
	const { loading, error, data, refetch } = useQuery(RESULTS_QUERY, {
		variables: {filter: '{"o_path": {"$like" : "'+path+'"}, "o_modificationDate": {"$gt" :"'+modefiedSince+'"}, "status": {"$like" : "%ok%"}}'  },
		pollInterval: 10000,
		onCompleted: data => {
			const edges = data.getKanallopetResultListing.edges;
			const fetchedResults = edges.map(result => {return {id: result.node.id, result: result.node.result, status: result.node.status, 
				pagination: result.node.pagination, runner: result.node.runner, modificationDate: result.node.modificationDate };});
			
			const newResults = [];
			allResults.forEach(result => {
				if (!fetchedResults.find(r => {return r.pagination === result.pagination})) {
					newResults.push(result);
				}
			});
			newResults.push(...fetchedResults);
			allResults = newResults.filter(r => r.runner).sort((a,b) => b.pagination-a.pagination);
			
			allResults.forEach(result => {
				modefiedSince = result.modificationDate > modefiedSince ? result.modificationDate : modefiedSince;
			});
			
			const allRankings = {'10 km': {rankings: []}, '21 km': {rankings: []}, '21 km K': {rankings: []}, '21 km M': {rankings: []}, '5 km': {rankings: []}};
			allResults.forEach(result => {
				allRankings[result.runner.competitionClass.name].rankings.push(result);
			});
		
			const now = moment();			
			const exportData = {classes: [], updated: now.add(modefiedSince*1000 - now).format('YYYY-MM-DD HH:mm:ss')};
			for (const key in allRankings) {
				const classData = {name: key, results: []};
				const classRanking = allRankings[key];
				classRanking.rankings = classRanking.rankings.sort((a, b) => compare(a.result, b.result));
				classRanking.rankings.forEach((r, i) => {
					r.place = i+1
					classData.results.push({id: r.runner.key, place: i+1, name: r.runner.name, club: r.runner.club, result: printFormat(r.result)});
				});
				exportData.classes.push(classData);
			};

			if (json === null || json.updated !== exportData.updated) {
				json = exportData;
				sendResults(JSON.stringify(exportData));
			}	
			
		},
		onError: data => {
			console.log('Exporting results failed');			
		}
	});
	
	const sendButtonClicked = () =>  {		
		sendResults(JSON.stringify(json));
	}
	

	

	if (loading ) {
		console.log('loading results');
		return (
			<div>
				Laddar resultat ...
			</div>
		);
	}
	if (error) {
		console.log('error loading results');
		return `Error! ${error}`;
	}

	
	return (
		<>
		 	Resultat uppdaterat: {json ? json.updated : ''}
			<br/>
			Skickat: {sent}
			<br/>
		    <button onClick={() => sendButtonClicked()}>Skicka om</button>
			<p>
				{JSON.stringify(json)}
			</p>
		</>
	);
}

export default LiveExport;