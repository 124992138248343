import React,{ useState }  from 'react';
import Main from './Main';
import { Link } from "react-router-dom";
import SignIn from './SignIn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarChart, } from '@fortawesome/free-regular-svg-icons'
import { getSiteUrl } from './Config'

const API_TOKEN = 'apiToken'; 
const YEAR = 'year';
const SERVER = 'server';


 
function App() {
	/* Toggle between showing and hiding the navigation menu links when the user clicks on the hamburger menu / bar icon */
		const toggleMenu = (title) => {
		var x = document.getElementById("myLinks");
		  if (x.style.display === "block") {
		    x.style.display = "none";
		  } else {
		    x.style.display = "block";
		  }
		//setHeadline(title);
	}

	// api-nyckel: e1fa06ab0f4616ec8a533574742dff34
 	const token = localStorage.getItem(API_TOKEN);
	const year = localStorage.getItem(YEAR);
	const server = localStorage.getItem(SERVER);
	console.log('token in App is '+token);
	const [isSignedIn, setIsSignedIn] = useState(token);
	const [yearPath, setYearPath] = useState(year);
	const [serverUrl, setServerUrl] = useState(server);
	const [siteUrl, setSiteUrl] = useState('');
	
	const login = (token) => {
		localStorage.setItem(API_TOKEN, token);
		setIsSignedIn(token);
	}
	
	const logout = () => {
		const doIt = window.confirm("Om du gör detta kommer du behöva en inbjudningslänk, eller kunna fylla i api-nyckeln, för att kunna fortsätta använda appen.");
		if (doIt) {
			localStorage.removeItem(API_TOKEN);
			setIsSignedIn(0);
		}
		
		toggleMenu();
		return doIt;
	}
	
	const setYear = (year) => {
		localStorage.setItem(YEAR, year);
		setYearPath(year);
	}
	
	const setServer = (server) => {
		localStorage.setItem(SERVER, server);
		setServerUrl(server);
	}
	
	const toInvite = window.location.pathname === '/invite';
	const startListUrl = siteUrl + 'anmalan/startlista.php'
	
	const yearWarning = () => {
		
		if (new Date().getFullYear() != yearPath) {
			return (<div className='yearwarning'>⚠️Du är inte på innevarande år.⚠️</div>);
		}
		return null;
	}

    return (    
       <div className="App">

<div className ="topnav">
	<a className="nobreak" onClick={toggleMenu}><FontAwesomeIcon className="nobreak" icon={faBarChart} /></a><h1>Kanallöpet {yearPath}</h1>
		{yearWarning()}
	  <div id="myLinks">
		<Link onClick={toggleMenu} to="/">Hem</Link>
		<Link onClick={toggleMenu} to="/apply">Anmälan</Link>
	    <Link onClick={toggleMenu} to="/finish">Mål</Link>
		<Link onClick={toggleMenu} to="/results">Resultathantering</Link>
		<Link onClick={toggleMenu} to="/resultdisplay">Målresultat</Link>
		<Link onClick={toggleMenu} to="/resultprinting">Resultatutskrift</Link>
		<Link onClick={toggleMenu} to="/liveexport">Liveresultat-export</Link>
		<a    onClick={toggleMenu} href={startListUrl}  target='_blank'>Startlista</a>
		<Link onClick={toggleMenu} to="/startlist">Startlista utskrift</Link>
		<Link onClick={toggleMenu} to="/fetchapplications">Hämta anmälningar</Link>
		<Link onClick={toggleMenu} to="/classes">Klasser</Link>
		<Link onClick={toggleMenu} to="/signin">Inställningar</Link>
		<Link onClick={toggleMenu} to="/readingunit">Enhet</Link>				
		<Link onClick={logout} to="/">Logga ut</Link>
	  </div>
  
</div>
			{isSignedIn || toInvite ? <Main setYear={setYear} setIsSignedIn={login} token={token} year={yearPath} server={server} setServer={setServer} setSiteUrl={setSiteUrl}/> : 
				<SignIn setIsSignedIn={login} setYear={setYear}  token={token} year={year} server={server} setServer={setServer}/>}
       </div>
 
    );
  }

export default App;    
