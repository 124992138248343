import React,{ useState, useEffect } from 'react';
import ApplyForm from './ApplyForm';

let nextNo = 0;
let ignorePreSelect = true;

function ApplyClass({classObject, apply}) {
	
	const unselected = {startno: '', name: '', club: '', dob: '', status: 'Vakant'};
	const [showForm, setShowForm] = useState(false);
	const [selectedRow, setSelectedRow] = useState(unselected);
	
	useEffect(() => {
		setShowForm(classObject && showForm == classObject.name ? classObject.name : false);
		if ((!classObject || showForm != classObject.name) && selectedRow.startno != 0) {
			setSelectedRow(unselected);
		}
    });

	if (!classObject) {
		return null;
	}

	const onSave = (application) => {
		console.log('save '+application.name);
		setShowForm(false);
		ignorePreSelect = false;
		nextNo = application.startno < classObject.bibend ? application.startno + 1 : 0;
		setSelectedRow(unselected);
		application.raceclass = classObject.id;
		let app = application;
		if (!app.reference) {
			app.reference =  Date.now().toString();
			app.status = 'Ifylld';
			classObject.lates.push(app);
		} 
		else {
			app = classObject.lates.find(a => (a.reference === application.reference));
			Object.keys(application).forEach(name => app[name] = application[name]);
			app.status = 'Uppdaterad';
		}
		localStorage.setItem(classObject.id, JSON.stringify(classObject.lates));
		apply(app);
	}
	
	
	
	const form = () => {
		if (!showForm) {
			return null;
		}
		return (
			<ApplyForm application={selectedRow} onSave={onSave} onStartNoChange={onStartNoChange} from={classObject.reservedStart} to={classObject.bibend}/>
			);
	}
	
	/**
	 * 			<div className="modal">
				<div className="modal_content">
					<span className="close" onClick={() => {ignorePreSelect = true;setShowForm(false); setSelectedRow(unselected);}}> &times;</span><br/>
					
				</div>
			</div>
	 */
	

	const selectRow = (application) => {

		//let application = classObject.lates.find(r => (r.startno == startNo));
		if (application.status !== 'Vakant' && !application.reference) {
			setSelectedRow(unselected);
			return;
		}		
		setSelectedRow(application);
		setShowForm(classObject.name);		
	}
	
	const onStartNoChange = (startno, onlyNew = false) => {
		const app = classObject.lates.find(a => (a.startno == startno));
		if (onlyNew && app) {
			return;
		}
		if (startno < classObject.reservedStart || startno > classObject.bibend) {
			return;
		}
		selectRow(app || {startno: startno, name: '', club: '', dob: '', status: 'Vakant'});
	}
	
	const selectButton = (application) => {
		if (application.status !== 'Vakant' && !application.reference) {
			return;
		}
		return (<button type="button" onClick={() => selectRow(application)}>🖊</button>);
	}
	
	
	if (!classObject) {
		return null;
	}
	
	if (nextNo && !ignorePreSelect && !showForm) {
		onStartNoChange(nextNo, true);
	}
	
	let storedClass = localStorage.getItem(classObject.id);
	if (storedClass && storedClass !== 'undefined') {
		storedClass = JSON.parse(storedClass);
		storedClass.forEach(app => {
			const r = classObject.lates.find(a => (a.startno == app.startno));
			if (r) {
				Object.keys(app).forEach(name => r[name] = app[name]);
			}
			else {
				classObject.lates.push(app);
			}
		});
	}


	const apps = [];
	for (let i = classObject.reservedStart; i<=classObject.bibend; i++) {
		const r = classObject.lates.find(a => (a.startno == i));
		if (r) {
			apps.push(r);
		}
		else {
			apps.push({startno: i, name: '', club: '', dob: '', status: 'Vakant'});
		}
	}

	return (
		<>
			<h1>{classObject.name}</h1>
			
			<div className="applyformcontainer">
				<ApplyForm application={selectedRow} onSave={onSave} onStartNoChange={onStartNoChange} from={classObject.reservedStart} to={classObject.bibend}/>
		    </div>
		
		<div className="tableContainer">
			<table className="applyListTable">
			<thead>
				<tr key="0"><th>Nummer</th><th>Namn</th><th>Klubb/Ort</th><th>Födelseår</th><th >Status</th><th ></th></tr>
			</thead>
			<tbody>
				{apps.map(r => ( <tr key={r.startno} className={(selectedRow.startno == r.startno ? 'selectedapplication ' : ' ') + (r.status === 'Ifylld' || r.status === 'Uppdaterad' ? 'applyremains' : '')}
				   onClick={() => selectRow(r)}><td>{r.startno}</td><td>{r.name}</td><td>{r.club}</td><td>{r.dob}</td><td >{r.status}</td>
				<td>{selectButton(r)}</td></tr> ))}
			</tbody>
		</table>
		</div>
		</>
		);
}


export default ApplyClass;
