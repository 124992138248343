import React,{ useState, useEffect } from 'react';
import {
	useQuery,
	gql,
} from "@apollo/client";

import { getSiteUrl, getSitePwd, getSiteYear, getProxyUrl } from '../Config' 
import {getClasses} from '../Class/Class';
import ApplyClass from './ApplyClass';
import ApplyAny from './ApplyAny'

const anyClass =  {id: 'any', bibend: 0, lates: []};

function Apply({year}) {
	
	const [classes, setClasses] = useState([]);
	const [currentStart, setCurrentStart] = useState(null);
	const [currentClass, setCurrentClass] = useState(null);
	const [change, setChange] = useState(0);	
	
	
	const fetchClasses = () => {
		let url = getSiteUrl() + 'anmalan/admin' + getSitePwd() + '/exportlaterunners.php?year='+getSiteYear();
		const proxyUrl = getProxyUrl();
		if (proxyUrl && proxyUrl.startsWith('http')) {
			url = proxyUrl+'?target='+encodeURI(url);
		} 
		
		var xhttp = new XMLHttpRequest();
		xhttp.onreadystatechange = function() {
		    if (this.readyState > 3 && this.status == 200) {
		      setClasses(JSON.parse(xhttp.responseText));
		      localStorage.setItem('classesresponse', xhttp.responseText);
		    }
		    else {
		    	console.log('Misslyckades:'+this.status +':' +this.readyState +':' + this.responseText);
		    	if (this.readyState > 3) {
					const storedClasses =  localStorage.getItem('classesresponse');
					if (storedClasses) {
						console.log('Loading from local storage');
						setClasses(JSON.parse(storedClasses));
					}
						
				}
		    }
	    };
		xhttp.open('GET', url, true);		
		xhttp.send();
	}
    const apply = (application, isAnyClass = false) => {
		if (!application.reference) {
			alert("Kan inte spara denna.");
			return;
		}

		let data = "reference="+application.reference;
		data += "&name1="+application.name;
		data += "&dob1="+application.dob;
		data += "&club1="+application.club;
		data += "&raceclass1="+application.raceclass;
		data += "&bus1=Nej";
		data += "&late1=Ja";
		data += "&startno1="+application.startno;
		data += "&paid=1";
		data += "&paymenttype="+application.paymenttype;
		
		let url = getSiteUrl() + 'anmalan/admin' + getSitePwd() + '/apply.php?year='+getSiteYear();
		const proxyUrl = getProxyUrl();
		if (proxyUrl && proxyUrl.startsWith('http')) {
			url = proxyUrl+'?target='+encodeURI(url);
		} 
		var xhttp = new XMLHttpRequest();
		xhttp.onreadystatechange = function() {
		    if (this.readyState > 3 && this.status == 200) {			  
		      const response = JSON.parse(xhttp.responseText);
		      console.log(application.name + ' sparad '+response.status);
		      if (response.status === 'success') {
				  application.status='Anmäld';
				  if (isAnyClass) {
					  localStorage.setItem(anyClass.id, JSON.stringify(anyClass.lates));
				  }
				  else {
					  localStorage.setItem(application.raceclass, JSON.stringify(currentClass.lates));
				  }
				  setChange(Date.now());
			  }
		    }
	    };
		xhttp.open('POST', url, true);
		xhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
		xhttp.send(data);
	}
	
	useEffect(() => {
		// wait for site url to be loaded
		 const interval = setInterval(() => {
				const url = getSiteUrl()
				if (url) {
					clearInterval(interval);
					fetchClasses();
				}
			}
			, 100);		
    }, []);
    
    const changeStart = (start) => {
		console.log("Ändra till "+start.name);	
		setCurrentStart(start);
		setCurrentClass(start.classes[0]);
	}
	
	const changeClass = (classObj) => {
		console.log("Ändra till "+classObj.name);
		setCurrentClass(classObj);
	}
	
	if (!Array.isArray(classes)) {
		console.log("Classes is not na array");
		return null;
	}
	
	const starts = {};
	classes.forEach(c => {
		if (!starts.hasOwnProperty('start'+c.length)) {
			starts['start'+c.length] = {name: 'Start '+c.length+' km', classes: []};
		}
		starts['start'+c.length].classes.push(c);
	});
	

	return (
		<>
			{currentStart ? <button onClick={() => {
				setCurrentStart(null);
				setCurrentClass(null);
			}}>🔙</button> : Object.keys(starts).map(sid => (<span key={sid}><button className='applystartbutton'
																					type="button" onClick={() => {
				changeStart(starts[sid])
			}}>{starts[sid].name}</button>
				&nbsp;</span>))}
			{currentStart && currentStart.name !== 'Any' ? (
				<div className="applyClassDiv">
					{currentStart ? currentStart.classes.map(cl => ( <><button key={cl.id} className='applyclassbutton' type="button"  onClick={() => {changeClass(cl)}}>{cl.name}</button> &nbsp;</> )) : ''}
					<ApplyClass classObject={currentClass} apply={apply}/>
				</div>
			) : ''}


			{currentStart ? '' : (<button className='applystartbutton' type="button" onClick={() => {
				changeStart({name: 'Any', classes: [{}]})
			}}>Udda löpare</button>)
			}
			{currentStart && currentStart.name === 'Any' ? (
				<div className="applyClassDiv">
					<ApplyAny classObject={anyClass} apply={apply}/>
				</div>) : ''}

		</>
	);
}


export default Apply;
