import React, { useState } from 'react';
import ErrorMessage from '../ErrorMessage';
import { gql, useQuery, useMutation } from '@apollo/client';

const UNIT_STORAGE_KEY = 'reading-unit';
const UNITS_STORAGE_KEY = 'reading-units';

const READING_UNITS_QUERY = gql`
 query getKanallopetReadingUnitListing($filter: String!)  {
  getKanallopetReadingUnitListing(filter: $filter) {
    edges {
      node {
        id
		key
		name
		readingType
		primary
      }
    }
  }
}
`;

function UnitSelect({year, unit, setUnit}) {
	const [readingUnits, setReadingUnits] = useState([]);
	
	const getUnit = id => {
		const res = readingUnits.find(u => u.id == id);
		return res ? res : null;
	}
	
	const setupUnits = list => {
		setReadingUnits(list);
		const selectedUnit = list.find(u => u.id === (unit ? unit.id : 0));
		if (!selectedUnit) {
			setUnit(null);
		}
	}
	
	const { loading: lLoading, error: lError, data: lData } = useQuery(READING_UNITS_QUERY, {
			variables: { filter: '{"o_path": {"$like" : "/kanallopet/'+year+'/readings/"}}' },
			onCompleted: data => {
				const list = data.getKanallopetReadingUnitListing.edges.map(el => ({id: parseInt(el.node.id), key: el.node.key, name: el.node.name, readingType: el.node.readingType, primary: el.node.primary}));
				if (list.length) {
					setupUnits(list);
					localStorage.setItem(UNITS_STORAGE_KEY, JSON.stringify(list));
				}
				
			},
			onError: data => {
				const units = localStorage.getItem(UNITS_STORAGE_KEY);
				if (units) {
					const list = JSON.parse(units);
					setupUnits(list);
				}
			}
		});

	
	const assign = (event) => {
		const selectedId = event.target.form[0].value;
		console.log('assign to unit '+selectedId);
		localStorage.setItem(UNIT_STORAGE_KEY, JSON.stringify(getUnit(selectedId)));
	}
	
	const changeUnit = (event) => {
		console.log('selected unit '+event.target.value);
		const unit = getUnit(event.target.value);
		setUnit(unit);		
	}
	

	return (
		 <div>
			<ErrorMessage message={lError ? lError.message : ''} />
			<span>{lLoading ? 'Läser in ...' : '' }</span>
			<form>
				<select value={unit ? unit.id : 0} onChange={changeUnit}>
				 	 <option value="0">Välj</option>
					 {readingUnits.map(unit => (<option key={unit.id} value={unit.id} >{unit.name}</option>))}
				</select>
			</form>
			<br/>
        </div>
	);
}
export default UnitSelect;
