import React,{ useState } from 'react';
import {
	useLazyQuery,
	gql,
} from "@apollo/client";




function FetchApplications({year}) {


	const FETCH_QUERY = gql`
	  query fetchApplicationsQuery($fullpath: String!)		     {
	  	fetchApplicationsQuery(fullpath: $fullpath) {
		  count
      }
	}
	`;
	

	const [count, setCount] = useState('-');
	

	const path = '/kanallopet/'+year;
	
	const [fetchApplications, { loading, error, data, refetch }] = useLazyQuery(FETCH_QUERY, {
		notifyOnNetworkStatusChange: true, // to get onCompleted always to be called
		fetchPolicy: 'network-only', // to avoid using cache - needed when comming back by routing
		variables: {fullpath: path },
		onCompleted: data => {
			console.log('completed.');
			setCount(data.fetchApplicationsQuery.count);	
		},
		onError: data => {
			setCount(-1);			
		}
	});
	
	
	
	const fetchButtonClicked = () =>  {		
		refetch();
		setCount(-2);
	}
	
	

	if (loading || count == -2) {
		return (
			<div>
				Hämtar anmälningar ...
			</div>
		);
	}
	if (error) {
		console.log('error loading results');
		return `Error! ${error}`;
	}

	
	return (
		<>
		 	Antal hämtade anmälningar: {count}
			<br/>
		    <button onClick={() => fetchButtonClicked()}>Hämta anmälningar</button>
		</>
	);
}

export default FetchApplications;
