import React from 'react';
import { Link } from "react-router-dom";

function StartPage({ year }) {
	
	const APP_VERSION = '2.3.3';
	
	const panic = () => {
		const doIt = window.confirm('Vill du verkligen rensa allt?');
		if (doIt) {
			localStorage.clear();
			window.location.href = 'signin';
		}	
	}

	return (

		<div>
			Version: {APP_VERSION}
			<p>
				Detta är en app för att hantera tidtagning och resultatgivning för Kanallöpet. Appen kräver
				internetuppkoppling, men de olika
				enheterna som behövs för att genomföra tidtagningen, behöver inte vara kopplade till samma nät.
			</p>

			<h2>Anmälan</h2>
			I funktionen <Link to="/apply">Anmälan</Link> kan man mata in efteranmälda löpare. Man börjar med att välja
			start
			(första gången kan det ta någon sekund innan valen visas) och därefter klass (om fler än en).
			Här visas ett inmatningsformulär och en lista över klassens tilldelade efteranmälningsplatser. Observera att
			rutans storlek för listan är fast och får en egen scrollbar om hela listan inte får plats.
			Dvs, om listan är lång scrollar man i den gula rutan och inte i webbläsarens scrollbar.
			<p>
				När man ska fylla i en löpare, markerar man den rad med rätt startnummer. Man fyller i namn, klubb/ort
				och födelseår, samt
				vilket sätt löparen har betalt avgiften på och klickar därefter på
				spara eller slår på retur-tangenten. Status för raden ändras då till "Ifylld" eller "Uppdaterad"
				(beroende på om den är ny eller omsparad) och raden blir röd,
				ända tills anmälan är registrerad på
				servern. Statusen ändras då till anmäld. Nästa rad blir sedan automatiskt markerad.

				Om man tappar kontakten med servern, kan man fortsätta att mata in anmälningar. När man senare
				får kontakt igen, får man då spara om dessa. Det går att ändra uppgifter för en anmäld (som man själv
				matat in),
				genom att markera raden, ändra och spara.
			</p>
			<p>
				Om alla inmatade anmälningar man har gjort, har registrerats, kan man byta enhet (dator) och fortsätta
				registreringarna där. Dock går det inte ändra uppgifter
				på anmälningar som registrerats på en annan enhet.
			</p>
			<p>
				Anmälningarna registreras i Kanallöpets anmälningssystem och inte direkt i tidtagningssystemet.
				Funktionen "Hämta anmälningar" behöver användas
				för att föra över anmälningarna in i tidtagningssystemet.
			</p>

			<h3>Udda löpare</h3>
			Om man tvingas anmäla en löpare med ett nummer som inte finns listat för klassen, kan man använda funktionen
			"Udda löpare". Här kan man välja klass och startnummer fritt. Man behöver säkerställa att man
			inte använder ett startnummer som redan är tilldelat en annan löpare.


			<h2>Mål</h2>
			En av de viktigaste uppgifterna finns i funktionen <Link to="/finish">Mål</Link>. Där väljer man om man ska
			ha funktionen "Primär tidtagning",
			"Sekundär tidtagning" eller om man ska skanna nummerlappar. Det går att byta enhet för en funktion.
			Registreringar som gjorts på en annan enhet läses in,
			förutsatt att alla inmatningar har registrerats.
			Det är dock viktigt att det bara är en enhet som jobbar mot samma typ av målenhet/skanner samtidigt.

			<p>
				Avläsningarna sparas lokalt tills att de blivit registrerade på servern. Om man tillfälligt tappar
				nätkoppling,
				är det bara att fortsätta registrera tills man fått nätkoppling igen.
				Då kan man klicka på knappen "Skicka om oregistrerade" för att skicka om avläsningar som inte
				registrerats på servern.
				Inläsningen får ett Inläsnings-id när det registrerats på servern.
			</p>

			<h3>Tidtagning</h3>
			För tidtagningsfunktionerna, klickar man bara på knappen "Registrera" för varje löpare som passerar
			mållinjen. Alla måltider listas och
			får en paginering (förkortat Pag). När en måltid fått ett id, betyder det att den är sparad på servern. Det
			står "Registrerar..."
			medan tiden registreras på servern. Om det uppstår problem med registreringen så kommer inget id upp.
			Fortsätt ändå att registrera målgångar
			om detta sker. Tiderna sparas på enheten och registreras senare. Knappen "Skicka om oregistrerade" gör ett
			försök att skicka om misslyckade
			måltider. Normalt behöver inte det göras, utan de skickas om när kontakt återupptagits.

			Om man missar att registrera en målgång, eller råkar dubbelregistrera kommer det att upptäckas och
			hanteras i Resultathanteringsfunktionen.


			<h3>Läsa nummerlappar</h3>
			För att läsa in nummerlappar, kan man välja tre olika sätt, manuell, mobil eller handskanner.
			I manuellt läge så skriver man in numret och klicka retur, alternativt klicka på knappen "Registrera".
			<p>
				I mobilläge, skannar man av streckkoden med kameran. Man behöver då godkänna att appen får tillgång till
				kameran.
				När man väljer inmatningstyp mobil, får man upp en ruta som visar kamerabilden. Man riktar kameran mot
				streck-koden på nummerlappen.
				När koden är inläst visas en grön knapp med nummerlappens nummer. Man klickar på den för att registrera
				avläsningen.
				Det visas även en röd knapp, som ger möjlighet att göra en ny skanning, utan att den förra registreras.
			</p>
			<p>
				Väljer man handskanner ska man först ha kopplat in skannern i en USB-port. Man får då upp en ruta med
				tillgängliga portar
				och man väljer den med skannern kopplad till sig. Numret registreras direkt då streckkoden har scannats av.
			</p>
			<h4>Plakatstil av senast inlästa löpare</h4>
			I handskanner-läge visas efter scanning en stor ruta med startnummer och om en parning med löpare gjorts,
			namn förening och förhoppningsvis även en tid. Man kan även klicka på knappen "Löpare i stortext" för att
			öppna
			rutan. I rutan vänstra övre hörn visas pagineringen samt en upp- och en nedåtpil. Med pilarna kan man
			justera
			fontstorleken på texten. Med krysset i högra övre hörnet på rutan stänger man rutan.


			<h2>Resultathantering</h2>
			I funktionen <Link to="/results">Resultathantering</Link> kan man justera felaktiga/missade inläsningar.
			Man ser ihop-parninen av inläsningar till resultat. Hämtning av nya resultat/inläsningar görs var 5:e
			sekund.
			Man kan också forcera en hämtning genom att trycka på knappen 🔄. Behöver man av någon anledning läsa om alla
			resultat,
			kan man göra reload på sidan. Ibland kan den lokala uppdateringen av data hamna i osynk med servern. Då
			löser en reload av sidan detta.
			Paus-knappen <span>"⏸"</span> får hämtningarna från servern att ta paus och knappen ändrar sig
			till <span>"▶"</span>, som vid tryck
			återupptar hämtningarna.
			<p>
				Pagineringen längst till vänster är för resultatet och har i normalfallet 100-hopp. Men de två sista
				nollorna visas inte.
				Om resultatradens båda måltider diffar med mindre än en sekund och den också kopplats till en nummerlapp
				som tillhör en löpare
				kommer resultatet automatiskt godkännas.
			</p>
			<p>
				Id-kolumnen är ett internt id för resultatet. Tiden visas i kolumnen Löptid. Kolumnen för löpare visar
				startnummer, namn och klass för den löpare som är kopplad till resultatet.
				De grå kolumnerna är paginering för målinläsningarna (tider och nummerlapp).
				Siffran i parentesen i rubriken "Primär tid", Sekundär tid" och "Startnummer" anger aktuell
				förskjutning i paginering i förhållande till resultatets paginering. Denna förskjutning ändras om man
				tvingas lägga till eller ta bort avläsningar. Avläsningstiden med grön bakgrund är den
				som används som underlag till måltidsberäkningen. Tiden i startnummers-kolumnen är tiden då nummerlappen
				skannades.
				Det går även att välja den tiden som underlag för löptiden, ifall man inte vet vilken måltiden löparen
				borde ha.

				Löptidsberäkningen kan först göras när en löpare är kopplad till resultatet, eftersom det först då finns
				en tillgänglig starttid att göra beräkningen utifrån.
			</p>
			<p>
				Man kan markera rutan för löptiden, löparen, den primära tiden, sekundära tiden eller startnumret på en
				rad.
				Då visas en kombination av ett antal knappar ute till höger på raden. ❌➕⬅ 🖊✅
				Motsvarande knappar, med samma funktion, visas då även ovanför tabellen. Där med en titel. Om man
				hovrar över knapparna med musen, visas en kort text som beskriver funktionen. Beroende på vilken kolumn
				man har markerat, gör knapparna lite olika saker. Se nedan.
			</p>
			<h3>Synkpunkt</h3>
			Om man har hamnat ur synk med avläsningarna, kan man skapa en synkpunkt, för att avläsningarna efter ska
			hamna i synk igen.
			Under den sekund eller få sekunder det tar att skapa en synkpunkt, ska det inte passera någon löpare
			över mållinjen och ingen nummerlapp ska skannas. Bäst är att ingen löpare väntar på att bli skannad heller,
			men om man inte hinner invänta att alla i kö blir skannade
			har man möjlighet att ange hur många som står i kö då synkpunkten skapas. När man klickar på
			Synk-punkt-knappen
			får man en fråga om hur många löpare det står i kö till skanningen.
			Den är förifylld med värdet i den ruta i rött högst upp på sidan, som anger
			det antal löpare systemet anser borde stå i kö till skanningen. Det går också att avbryta skapandet av
			synkpunkten då denna fråga ställs.
			<br/>
			När synkpunkten skapats, har förkjutnings-siffran för avläsningskolumnerna justerats och en ny tom
			resultatrad skapas överst, där efterföljande avläsningar börjar kopplas.
			Undantaget om man angivet ett värde större än noll för antalet löpare i kö för skanning,
			eftersom det är avläsningar som ska kopplas till resultat före synkpunkten.
			<br/>
			En rosa linje visas i tabellen som märker ut var en synkpunkt har satts. Det är resultatet ovanför som är
			första resultatet efter en synk.


			<h3>Primär tid markerad</h3>
			<h4>❌ Ta bort</h4>
			Avläsningen kopplas bort ifrån resultatet och eventuella avläsningar "ovanför", upp till närmsta synkpunkt,
			förskjuts nedåt.
			Denna åtgärd använder man om det råkat komma in en primär måltid för mycket.

			<h4>➕ Skjut in</h4>
			Skjuter in en ny tom avläsning och skjuter eventuella avläsningar ovanför uppåt, upp till närmsta synkpunkt.
			Används om det saknas en primär måltid. Den inskjutna platsen för en saknad avläsning förblir tom. För att
			ge resultatet en tid
			får man ange den sekundära avläsningen ska användas, eller i nödfall tiden för skanningen. Man har även
			möjlighet att skriva in en löptid direkt. Se nedan.

			<h4>⬅ Använd tid</h4>
			Gör så att den primära tiden används för löpartidsberäkning. Detta är utgångsläget, men ger möjlighet att
			"ta tillbaka" avläsningen som tidsgrundande om man ändrat det till t.ex. den sekundära tiden tidigare.

			<h4>🖊 Ändra</h4>
			Om man vill koppla en specifik primär måltid till resultatet kan man med denna funktion ange den paginering
			man önskar koppla.
			Detta gör inga förskjutningar av avläsningar eller andra ändringar på kringliggande resultat, vilket i
			praktiken innebär att det
			går att koppla samma avläsning till flera resultat. Om man vill koppla bort en inläsning, anger man 0 (noll)
			som
			paginering.

			<h4>✅ Godkänn</h4>
			Sätter resultatet på raden som godkänt. Kräver att det finns en måltid och en löpare kopplad.


			<h3>Sekundär tid markerad</h3>
			<h4>❌ Ta bort</h4>
			Avläsningen kopplas bort ifrån resultatet och eventuella avläsningar "ovanför", upp till närmsta synkpunkt,
			förskjuts nedåt.
			Denna åtgärd använder man det råkat komma in en sekundär måltid för mycket.


			<h4>➕ Skjut in</h4>
			Skjuter in en ny tom avläsning och skjuter eventuella avläsningar ovanför uppåt, upp till närmsta synkpunkt.
			Används om det saknas en sekundär måltid. Den inskjutna platsen för en saknad avläsning förblir tom.
			Om den sekundära tiden är markerad som underlag för resultatet får man för att ge resultatet en tid,
			ange att den primära avläsningen ska användas, eller i nödfall tiden för skanningen. Man har även möjlighet
			att skriva in en löptid direkt. Se nedan.

			<h4>⬅ Använd tid</h4>
			Gör så att den sekundära tiden används för löpartidsberäkning.

			<h4>🖊 Ändra</h4>
			Om man vill koppla en specifik sekundär måltid till resultatet kan man med denna funktion ange den
			paginering man önskar koppla.
			Detta gör inga förskjutningar av avläsningar eller andra ändringar på kringliggande resultat, vilket i
			praktiken innebär att det
			går att koppla samma avläsning till flera resultat. Om man vill koppla bort en inläsning, anger man 0 (noll)
			som
			paginering.

			<h4>✅ Godkänn</h4>
			Sätter resultatet på raden som godkänt. Kräver att det finns en måltid och en löpare kopplad.


			<h3>Startnummer markerad</h3>
			<h4>❌ Ta bort</h4>
			Avläsningen tas bort och eventuella efterföljande startnummer ovanför, upp till närmsta
			synkpunkt, hoppar ner ett steg.
			Denna åtgärd använder man om det råkat komma in en nummerlappsläsning för mycket.

			<h4>➕ Skjut in</h4>
			Skjuter in en tom startnummerläsning och skjuter eventuella startnummer ovanför, upp till närmsta
			synkpunkt, uppåt.
			Denna åtgärd använder man om det saknas en inläsning av startnummer.
			För att lägga in ett saknat nummer använder man funktionen "Ändra" med kolumnen "Löpare" markerad.

			<h4>⬅ Använd tid</h4>
			Gör så att avläsningstiden används för löpartidsberäkning. Kan användas i nödfall om måltiden för löparen är
			okänd.

			<h4>🖊 Ändra</h4>
			Om man vill koppla en specifik nummerlappsavläsning till resultatet kan man med denna funktion ange den
			paginering man önskar koppla.
			Detta gör inga förskjutningar av avläsningar eller andra ändringar på kringliggande resultat, vilket i
			praktiken innebär att det går att koppla samma avläsning till flera resultat.
			Om man vill ta bort en inläsning, anger man 0 (noll) som paginering.

			<h4>✅ Godkänn</h4>
			Sätter resultatet på raden som godkänt. Kräver att det finns en måltid och en löpare kopplad.

			<h3>Löptid markerad</h3>
			<h4>🖊 Ändra</h4>
			Om man av någon anledning vill sätta eller ändra löptiden på ett resultat, använder man denna funktion.
			Tiden skrivs in på formatet mm:ss eller hh:mm:ss.

			<h4>✅ Godkänn</h4>
			Sätter resultatet på raden som godkänt. Kräver att det finns en måltid och en löpare kopplad.

			<h3>Löpare markerad</h3>
			<h4>🖊 Ändra</h4>
			Antingen markerar man löpare-kolumnen för ett resultat och klickar på <span>🖊</span>-knappen,
			eller om kolumnen är tom, finns en likadan knapp direkt i fältet. Då kan man klicka på den direkt.
			När man klickat på knappen, får man ange ett startnummer. En kontroll görs att en löpare är kopplad till
			numret.
			Är det inte finns det, ges ett felmeddelande, annars skrivs löparen in och om möjligt beräknas löptiden.

			<h4>✅ Godkänn</h4>
			Sätter resultatet på raden som godkänt. Kräver att det finns en måltid och en löpare kopplad.

			<h3>Status</h3>
			Om primär tid och sekundär tid skiljer sig med mindre än en sekund och ett startnummer finns och är
			kopplat till en löpare, anses den beräknade måltiden som ok och godkänns automatiskt. Detta markeras
			med <span>✅</span>.

			Om Primär och sekundär tid diffar med mer än en sekund, markeras det med <span>🚩</span>. Om ett startnummer
			är angett, men ingen matchande löpare hittas, markeras det med <span>🚷</span>.

			<h3>Skapa resultat</h3>
			Med denna funktion kan man lägga till ett resultat helt manuellt. En manuellt tillagd resultatrad läggs till
			längs ned på sidan
			och får en pagineringsserie som prefixas med bokstaven M och börjar på 99 och räknar nedåt. När raden är
			tillagd, kan man sedan manuellt mata in startnummer och tid.


			<h2>Målresultat</h2>
			<Link to="/resultdisplay">Målresultat</Link> listar senaste resultaten. Funktionen kan användas för att visa
			upp resultat efter målgång.

			<h2>Resultatutskrift</h2>
			Funktionen <Link to="/resultprinting">Resultatutskrift</Link> möjliggör utskrift av resultat per klass.
			Man kan justera fontstorlek för
			klassrubriken och resultatraderna. Det finns en knapp per klass och på den kan man även se antalet resultat
			i klassen.
			När man klickar på en klass-knapp, öppnas en förhandsgranskningsruta där man kan välja skrivare och skriva
			ut.

			<h2>Liveresultat-export</h2>
			Funktionen <Link to="/resultprinting">Liveresultat-export</Link> startar export av resultat till Kanallöpets
			hemsida.
			En inläsning sker var tionde sekund och resultaten exporteras automatiskt. Knappen "Skicka om" forcerar en
			ny sändning av resultaten.
			Observera att funktionen endast är igång så läne sidan är öppen. Byter man funktion, avbryts exporteringen
			av resultat.


			<h2>Startlista</h2>
			I denna version av appen länkas bara till startlistan Kanallöpets hemsida, som öppnas i ett nytt fönster.

			<h2>Startlista utskrift</h2>
			Här visas en startlista per klass. Man kan välja att lista även löpare som ej har betalat. Det står då nej i
			den högra kolumnen
			och raden är rödmarkerad. Man kan sortera klasslistorna efter namn eller startnummer och det finns en
			utskriftsfunktion per klass.

			<h2>Hämta anmälningar</h2>
			I funktionen <Link to="/fetchapplications">Hämta anmälningar</Link> kan man trigga en hämtning av
			anmälningar från Kanallöpets
			anmälningssystem till tidtagningssystemet. Alla efteranmälningar som görs, registreras i anmälningssystemet
			och måste hämtas över
			med denna funktion. Hämtningar kan göras flera gånger, men det rekommenderas inte att man gör en hämtning
			samtidigt som inmatning pågår.

			När hämtningen är klar, bekräftas det med att antalet inlästa anmälningar visas. Alla anmälningar läses
			in. Eventuella ändringar på en löpare uppdateras därmed.

			<h2>Klasser</h2>
			Under <Link to="/classes">Klasser</Link> kan man ändra starttid för klasserna.

			<h2>Inställningar</h2>
			Under <Link to="/signin">Inställningar</Link> kan man bland annat kopiera en inbjudningslänk och dela till
			andra, som också ska hjälpa till.
			Man kan här ställa in vilket år man jobbar mot. Normalt ska det vara inställt på aktuellt årtal och det
			visas också i appens rubrik.
			Det går att ställa in andra värden, vid t.ex. testtillfällen, men det kräver förberedelser i serverns
			admingränssnitt.

			<h2>Enhet</h2>
			"Enhet"-funktionen används för att registrera enheter och används bara under förberedelse. Man kan också
			tömma de lokalt satta paginerings-värdena för enheterna.
			Det kan vara användbart vid tester, då man vill börja om och har tagit bort alla
			registreringar från servern.

			<h2>Meny</h2>
			Uppe till vänster finns en meny för tillgång till funktionerna.

			<h2>Panikrensa</h2>
			Längst ner på startsidan finns en knapp "Panikrensa". Denna funktion rensar all data sparad för denna app
			och kommer kräva en ny inloggning, eller att man följer en inbjudningslänk på nytt.
			Klickar man på knappen får man en fråga för att bekräfta att funktionen verkligen ska utföras.

			<h2>Förslag på nya funktioner och förbättringar</h2>
			<ul>
				<li>Funktion för att kunna göra alla förberedelser i appen. Skapa nytt år, läsa över samtliga data från
					kanallopet.com, editera inställningar.
				</li>
			</ul>
			<br/>
			<br/>
			<button onClick={panic}>Panikrensa</button>
			Tömmer all lagrad data. Kräver inloggning på nytt.
		</div>
	);
}

export default StartPage;
