import React from 'react';
import { printFormat } from './ResultUtil';
	
//function ResultPrintingClass({resultClass}) {
class ResultPrintingClass extends React.PureComponent { 
	constructor(props) {
	    super(props);
this.count = 0;
	}
	


	render() {
		const resultClass = this.props.resultClass;

		let c = 0;
		const hSize = this.props.headingSize || 30;
		const lSize = this.props.lineSize || 20;
console.log(resultClass.startAt);
		return (
			<div className="printresult">
			<h1 style={{fontSize: `${hSize}px`}}>{resultClass.name}</h1>
			<table className="resultdisplaytable" >
				<tbody>
					{resultClass.results.map(result => ( <tr className="resultdisplayrow" key={result.id+c++} style={{fontSize: `${lSize}px`}}>  
													<td align="right">{result.place}</td>
													<td>{result.name}</td>
													<td>{result.club}</td>
													<td align="right">{printFormat(result.result)}</td>
												</tr>))}
				</tbody>
				
			</table>
			</div>
		);
}
}

export default ResultPrintingClass;
