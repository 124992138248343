import React,{ useState } from 'react';
import {
	useQuery,
	useMutation,
	gql,
} from "@apollo/client";

const CLASSES_QUERY = gql`
	 query getObjectFolder($fullpath: String!)  {
	  getObjectFolder(fullpath: $fullpath) {
	    children {        
	        ... on object_KanallopetClass {
			  id
	          name
	          length
	          adultPrice
	          adultLatePrice
	          youthPrice
	          youthLatePrice
	          reservedBibs
	          firstBib
	          startTime
	        }
	      }
	  }
	}
`;

const UPDATE_CLASS_MUTATION = gql`
	  mutation updateKanallopetClass($id: Int!, $startTime: String!) {
	  	updateKanallopetClass(id: $id, input: {startTime: $startTime}) {
		  success
	      message 
		  output {
			id
			 name
	          length
	          adultPrice
	          adultLatePrice
	          youthPrice
	          youthLatePrice
	          reservedBibs
	          firstBib
	          startTime
		  }
		}
	 }
`;

let classes = [];


function Class({year}) {

	const [count, setCount] = useState('-');
	const [state, setState] = useState({});
	
	const handleStateChange = (property, value) => {
		const rf = {...state};	
		rf[property] = value;
		setState(rf);
	}
	

	const path = '/kanallopet/'+year;
	
	const [callUpdateClass, { error: dError }] = useMutation(UPDATE_CLASS_MUTATION, { 
		variables: { }, 
		onCompleted: data => {
			console.log(data.updateKanallopetClass.success+' '+data.updateKanallopetClass.message);	
			
		},
		onError: data => {
			console.log('Updating class failed');			
		}
	});
	
	const { loading: loading, refetch: refetch } = useQuery(CLASSES_QUERY, {
		variables: { fullpath: '/kanallopet/' + year + '/classes'},
		onCompleted: data => {
			
			classes = data.getObjectFolder.children.map(cl => (cl)).sort((clA, clB) => (clA.firstBib - clB.firstBib));
			const startTimes = {};
			classes.forEach((cl) => {
				startTimes[cl.id] = cl.startTime;
			})
			setCount(classes.length);
			setState(startTimes);
		}
	});
	

	
	
	
	
	
	
	
	const update = (cl) => {
		callUpdateClass({variables: {id: parseInt(cl.id), startTime: state[cl.id]}});
	}
	
	const handleStartTimeChange = (event, id) => {	
		const rf = {...state};	
		rf[id] = event.target.value;
		setState(rf);
	}
	
	return (
		<>
		<table id="resultstable">
			<thead>
				<tr><th>Klass</th><th>Längd</th><th>Starttid</th><th></th></tr>
			</thead>
			<tbody>
				{classes.map(c => ( 
					<tr key={c.id}><td>{c.name}</td><td>{c.length} km</td> 
						<td><input  name={c.id} value={state.hasOwnProperty(c.id) ? state[c.id] : ''}  placeholder={'yyyy-mm-dd hh:mm:ss'} onChange={(e) => {handleStartTimeChange(e, c.id)}} /></td>
						<td><button onClick={() => (update(c))}>Uppdatera</button></td>
					</tr>))}
			</tbody>
		</table>
		</>
		);	
}

function getClasses() {
	return classes;
}

export default Class;
export { getClasses };
