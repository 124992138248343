import React from 'react';
import { Routes, Route } from 'react-router-dom';

import SignIn from './SignIn';
import Finish from './Finish/Finish';
import ReadingUnit from './ReadingUnit/ReadingUnit';
import Invite from './Invite';
import Results from './Result/Results';
import ResultDisplay from './Result/ResultDisplay';
import Config from './Config';
import StartPage from './StartPage';
import LiveExport from './Result/LiveExport';
import ResultPrinting from './Result/ResultPrinting';
import StartList from './StartList/StartList';
import FetchApplications from './Apply/FetchApplications';
import Class from './Class/Class';
import Apply from './Apply/Apply';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client";

const Main = ({setIsSignedIn, setYear, token, year, server, setServer, setSiteUrl}) => {
	
  const client = new ApolloClient({
	  uri: server+'pimcore-graphql-webservices/kanallopet?apikey='+token,
	  cache: new InMemoryCache()
  });

  return (
	<div>
		<ApolloProvider client={client}>
			<Config year={year} setSiteUrl={setSiteUrl}/>
		    <Routes> {/* The Switch decides which component to show based on the current URL.*/}
		      <Route path='signin' element={<SignIn setIsSignedIn={setIsSignedIn} token={token} setYear={setYear} year={year} server={server} setServer={setServer}/>} />
			  <Route path='finish' element={<Finish year={year}/>} />
			  <Route path='readingunit' element={<ReadingUnit year={year}/>} />
			  <Route path='invite' element={<Invite setYear={setYear} setIsSignedIn={setIsSignedIn} setServer={setServer} />} />
			  <Route path='results' element={<Results year={year}/>} />
			  <Route path='resultdisplay' element={<ResultDisplay year={year}/>} />
			  <Route path='' element={<StartPage year={year}/>} />
			  <Route path='liveexport' element={<LiveExport year={year}/>} />
			  <Route path='resultprinting' element={<ResultPrinting year={year}/>} />
			  <Route path='startlist' element={<StartList year={year}/>} />
			  <Route path='fetchapplications' element={<FetchApplications year={year}/>} />
			  <Route path='apply' element={<Apply year={year}/>} />
			  <Route path='classes' element={<Class year={year}/>} />
		    </Routes>
		</ApolloProvider>
	</div>
  );
}

export default Main;